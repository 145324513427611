import dayjs from "dayjs";
import { ICustomer } from "./customer";

const helper = (x: any) =>
  x.reduce((acc: any, field: any) => {
    if (field.fieldType !== "Select" && field.fieldType !== "MultiChoice") {
      acc[field.id] = field.key;
    }
    return acc;
  }, {});

const fieldIdMap: { [key: string]: string } = {
  "g22ZoKqq6H-4ZURn0VBLlQ": "Pay_Rate",
  "g34b-7XQzi49EiOEBFBDeQ": "Printing_instructions",
  "g4l30LMUbHgl3MQYZeBByA": "Business_Physical_Address_Zip",
  "g6ksU-FMAkKHdqrC62VB9Q": "Additional_Household_Members/Businesses_related_to_Name_Change",
  "g771FNi1d4Ocg6z957JHiA": "Employee_Names",
  "g7EXzO2tc-CmB_1-FStALQ": "Phone_Number_2",
  "gDW0g9Pxwu6bmeXHLilPjw": "SSN/EIN",
  "gFN_Izobe7eCdaSn1fFP9A": "Middle_Initial_-_Primary_Contact",
  "gGNZ3T_QSyKbqDUsQ2NLag": "First_Name_-_Client",
  "gIVhhl0Z1rXHSz6fU-9LLA": "Spouse_Preferred_Greeting",
  "gKSDmhuBGE19INoYrEVHjA": "Last_Name_-_Spouse",
  "gO7nON2Rlbq-hhdk-EZGug": "Mobile_Phone_-_Primary_Contact",
  "gPKeYDxmr-kvSS5gFgNHgg": "New_Employee_Phone_No",
  "gRFBrxE6GHvOleEscW9CHw": "City_-_Primary",
  "gRHauGEPgMIxo3QO5RhASQ": "Address_-_Primary",
  "gRmdLSk7NnIDSAFWUKdEEw": "Client_Email",
  "gS3bpAtaoU8jzXDwQYJIIw": "CFS_Client_ID_(From_Brain)",
  "gT1Zv1GUF_-gpnAJfURPlw": "Period_Start_Date",
  "gTPzpbvOcusIOAGOvLNOEg": "Client_Phone_(Work/Business)",
  "gUYnrob4VoSPmCcRhlxPTw": "Client_Copy_of_Tax_Return:",
  "gYhLNQyrksl9vqXzPNlG_Q": "Attachment_if_Applicable",
  "g_GCInu-jj8p3S3Hqv1MDg": "Last_Name_-_Client",
  "g_UT52CUDdfxEhCZaF1JOQ": "birthday",
  "gb6ybvUZlyW6qq7zKi1Oqg": "Client_First_Name",
  "gbPgjVuNGYpZOmILOh9O6A": "Folder_the_workflow_is_current_stored",
  "gee01GRg6hhI6o7Yi_xAIA": "Notes_or_Special_Instructions",
  "gf-ZNd_y-n1evYs6WyxBwA": "Last_Name_-_Client",
  "gflTX6o0Z3DCQUyshhRIDg": "Date_of_Meeting",
  "gia1S-KDjwtUf7N8jDFB_w": "Starting_Pay",
  "gijQ4Pfp_KcqPLq7XVlA9w": "First_Name_-_Client",
  "gl0xy-yT3iV31o9Zxa1BfQ": "Contact-Phone",
  "gnC53GycPOqJQUYIQ4pDuw": "Client_Phone_Number",
  "gouHOz1QaZ8M-GZ0b8xGDQ": "1040-V_(Federal_payment_voucher):",
  "gr7saTv_UpHVhXhXUMBEoQ": "Updated_Last_Name",
  "gtgsV1Ms6d84vFNXpkdE_w": "Email_Address_-_Primary_Contact",
  "guOxZjE9jrBqpQ4_EvNKLA": "New_Employee_Last_Name",
  "gwgVQBEEmTrCxOT4bzJALQ": "Email_-_Client",
  "gxTtDL4QhTozFzYhhipMGA": "Description_of_changes_made",
  "h3K7wTRaBydTJffAr0NMRA": "Last_Name_-_Client",
  "h4nAFAosW7dMdi1Oc9tJqQ": "Last_Day",
  "h4tluRM5GSbcwY4rbxtCtA": "Select_who_will_be_completing_the_training",
  "h4y5P6-DR0XhpP6pfv5PNg": "Zip_Code_-_Attorney",
  "h5BTal8h5hcoVGd_fXJKsA": "Personalised_meeting_agenda",
  "h5DFHunDFbl1UI3F7RxD1A": "Total_Family_AUM",
  "h6JUjrUq0-_KzwtPzoBOOg": "Business_Type",
  "h70UY_Yb4RWaHaqgMPlC5Q": "Zip_Code_-_Primary",
  "h8NVyyc6yC77yW5ltO5ITQ": "Last_Name_-_Client",
  "h9XBgrm7IwSmaXxtl-VNtw": "Care_of_Contact_Physical_City",
  "hA7OulrX6nEKkcO_8TNBIg": "Account_Name",
  "hCI1cUIKMg48TF3WdK1M5Q": "Client_Mailing_address_-_S",
  "hEJvl83JTzdZlnVe7-BOuA": "Last_Name_-_Client",
  "hG6ul-3ueLp38YUSn21ANA": "Company_Name",
  "hITHl55MGix1Hig6Az9Flw": "Supporting_Document_4",
  "hMYx-ofHBsQ0C2oxhoZDew": "Date_of_Death",
  "hModpv4eBlUdLt6Wz7BJRA": "City_-_Primary",
  "hNd0tD9YFCc4gRFu611Cug": "Gross_Amount",
  "hNp7-DmiXQHS24Un_CJCVQ": "Additional_Information",
  "hQ0jpnucZPRWb6rKH3NCCA": "Year_End-Calendar_Year_or_Fiscal_year",
  "hQ3ZSaqM6M14vELbFFBFOQ": "Owner_Phone_Number",
  "hU2GoP_vhO8qf-yWOTVJUg": "Stamping_instructions",
  "hUQpZamsVZbRkZNy-glBig": "Home_Phone_-_Primary_Contact",
  "hV615hcclxsLCtXS2wNImQ": "Date_of_Birth_-_Executor_2",
  "hVR2PqKVwBjjOiv-0iZGHg": "Project_Due_Date",
  "hXb25GDoyZVGgGAxmbZCbQ": "Client_Salesforce_ID_(Zapier)",
  "hY780ZRXGdQGXrqXFThM1g": "Spouse-Social_Security_Number",
  "hYTTcLSJdq1v4yrxvRlPtQ": "Last_Name_-_Client",
  "h_j_qisO17JgIOnQmblNTQ": "SSN_-_Primary_Contact",
  "haq9G9s5_hICk47DjENKiw": "Beneficiary_Information",
  "hiW35AnSmEQgT6PooZpAwQ": "Last_Name_-_Client",
  "himJwpn4Q655IcEmkOJBHg": "The_green_file_folder_should_look_as_follows:",
  "hkfMBZyxEw82UN5bcN5Ajw": "Email_Address_-_Primary_Contact",
  "hofSfkLZrMW_HnO4EUNOew": "Last_Paycheck_Date",
  "hq578dPWKkNqjin4i21ICw": "Care_of_Contact_Phone",
  "hxW9HF93_x_7IcywNMRDog": "Phone",
  "i2g08Twwra_jbN7U561GPw": "CFS_Client_ID_(From_Brain)",
  "i4qjcTOt0g19k-5Hk9VH9Q": "Attachment_(if_applicable)",
  "i6HO23oERjhDrv61qKRLog": "Tax_Notice_ID_Number",
  "i8uTf1Y9WNcE0XAehepEZA": "Date:",
  "i94MEDzjE-3GQd_Yix9OFg": "Trusted_Contact_Information",
  "iH1GBMCNS4vQavcBoPFLHA": "phone_number",
  "iI2DECiO0gx3_XOkSUZGtg": "full_name",
  "iLhgMjqmIsD7WXpZK0xJ6A": "New_Physical_Address",
  "iM38n2JmOqXnOLNGuzpI9A": "Primary_Clients_Email_Address",
  "iStlA0LYxyNxAO9m4nRP8A": "Client_-_First_Name",
  "iTLuuyp4nU3oipPUhJ9C1g": "First_Name_-_Spouse",
  "iTZRe1bfPCVLbGlWRVBDXA": "First_Name_-_Client",
  "iUOWFwQ20Y2KT8M5COxM3w": "Last_Name_-_Executor_2",
  "iVE0K5Cw-b32qxGzU1xPlw": "Spouse_Email_Address",
  "iVs3UVoFDxKO4zYelTZHJA": "SSN_-_Spouse/Partner",
  "iaLat8s47emiIvJdoEFFJg": "CFS_Client_ID_(From_Brain)",
  "ifYqj7o5GWkXZw4aKn1BVw": "Date_Change_Received",
  "ihUTSbtOsf45cFGb0mZPRQ": "Dependent_Information",
  "iixHz5a0sFegeClsc1NBgQ": "Care_of_Contact_Email",
  "ij5Kae3LhgHJ93Ypm-xP0g": "Trusted_Contact_Information",
  "ilsBRdIMO1ukrkbNqr1Osg": "Legal_Business_Name",
  "imwRZzrSIOT66YEumQRBiQ": "CFS_Client_ID_(From_Brain)",
  "in2wvtpPwmKH2HlgquRHCQ": "Stamping_checklist",
  "inFCZKWlWm6igk1Bjx1GbA": "Beneficiary/Trustee_First_Name",
  "ioi7Pwu4-jfD6SrQ4NBKWg": "target_completion_date",
  "ip5Z_x5j43XYYGkjqwtA7w": "Link_to_screen_recording",
  "iqipyeaZIrZyJXH4vFVEJw": "Account_Name",
  "iwlL00uUtTtja7PN0jFDKw": "Date_of_Birth_-_Primary_Contact",
  "ixk6XcNAvI3LGK-GDgpF2A": "Last_Name_-_Spouse/Partner",
  "j-XbP3ba7B7rNFX3RfNNcA": "CFS_Client_ID_(From_Brain)",
  "j1lMYcVxpGWTqUc2sRdKxg": "Period_End_Date",
  "j3WU-bVTaXMyKfsejiFMIQ": "Client_Mailing_Address_-_Street",
  "j3_7pamnkPzv84TdIeBPMQ": "client_address",
  "j7XU9PZkiVLqBpuKThtFzQ": "Phone_Number_-_Primary_Contact",
  "j8qa8nWCQyZNmGcFJxVC1g": "Date_Change_Received",
  "j9Iyo_6m4A_s9RWjxPpCPA": "Owner-First_Name",
  "j9K1iuiuq6QgOujqYZNK0Q": "Last_Name_-_Account_Holder",
  "j9lamnaK72ySEDJV4ZFH8w": "Copy_of_Notice",
  "jBsi1koh7GZqexUiNsVOSw": "Attach_original_report_document",
  "jCtQobRM5vbAP7SEhB1Mmg": "Client_Name(s)",
  "jEwm_SZL684Gvimbz9pFzg": "Care_of_Contact_Mailing_Zip",
  "jHCMeQx1ZKVqRnKRmxFJrQ": "Email",
  "jKpzD0vNqXr8gr6YrjtO8A": "Business_Physical_Address_Street",
  "jLHAjodL_TIq4vke0olIfA": "Is_this_a_Fiscal_Year_or_Calendar_Year_end?",
  "jQiBiuIaC2xH4RmqmzdDmw": "End_Date",
  "jTza9NsF9r0DVLpEcr5Mcw": "Email_Address_-_Primary_Contact",
  "jURKR705go_3bZX1FoBPVQ": "Start_Year",
  "jUx8FtfVcHCymgqKk5BDKQ": "Position",
  "jXzwOzm39ubpw9HYhE9PCw": "Owner-Address",
  "jY0EyUtBAAsPhjfrCNhNLA": "First_Name_-_Primary_Contact",
  "j_eXWwmWvnp6v1BjCRVGuw": "Email_-_Client",
  "jbH1kdL8BZ0T_V-joa9N0g": "Client_Mailing_address",
  "jcvhHSYKtTsagQSYoYdIJQ": "Middle_Initial_-_Client",
  "jdt5eOhUxaLeZvgoBYRB-Q": "Funds_to_Sell_-_Details",
  "jfUdnOymOoSLMS6pwvVMJQ": "Zip_Code_-_Primary",
  "jhRlWSgJ9wUTD-efJcFCYg": "due_date",
  "jjMYVqhJK72ch3g2YPNIeQ": "Completion_Deadline",
  "jlVZnFWnP09i8J2rqL5N9Q": "https://support.drakesoftware.com/AuthMenu/Home",
  "jtUMvdOCgm7Q4n_QbExBaw": "End_Year",
  "juNyYd4WsR0Ud-0ldtpGKw": "Additional_Household_Members/Business_related_to_Termination",
  "jx0vQ63lUs7BRQVGJfxCFw": "Last_Name_-_Joint_Owner",
  "jyFVFUS3BTUSsu9M0UlJ3w": "Does_this_client_have_vouchers_for_taxes_due,_or_for_estimated_taxes_for_the_next_tax_year?",
  "jydPDJAQqQBT3mK2e0lOiQ": "Email_-_Account_Holder",
  "jyqg6jvZgNO0d-H86_ZBQg": "Social_Security_Number_-_Account_Holder",
  "k5FPsByGNmFth8onR7NH2Q": "Incorporated_Date",
  "k69np_5t5b3Tm8gXqERK3Q": "Current_Mailing_Address_-_Street",
  "k8bfeieWKHWAgFSgIJ5Dyw": "Zip_Code_-_Executor_2",
  "kC0YR1yVRJQnpf67yRFI9g": "Voided_Check",
  "kDwpxf_jO6G0re9ufTRG-w": "Salesforce_ID_(for_automation)",
  "kKDqSl08j2vaLX1cUh5M6w": "City_-_Executor_2",
  "kLRwc4iU16sD2TtEc75BOQ": "address_2",
  "kLl0YRub2dJQyQFMuFhO9A": "First_Name_-_Client",
  "kMrQsI4BVrKZB8noWsBPYA": "Additional_Household_Members/Business_related_to_Termination",
  "kRCumDOHQphNWKgJywpFxA": "New_Physical_City",
  "kUYl7FuDXcUDuRe6mntNow": "IT-201-V_(NYS_payment_voucher):",
  "kWPceOLf-c2KqnBm_e9Akw": "Business_Address",
  "kZwl9wmnIPiWqZuJtelJ3Q": "Care_of_Contact_Last_Name",
  "k_cCYPt2IkizAAYVQ8NK3w": "Additional_Household_Members/Business_related_to_Email_Change",
  "kc8wmS1BHdtmj2RxPadKgA": "Year",
  "kd5QqThm_L63fdHoaoZPDA": "Additional_Information",
  "kf8uiJm0otAvOD8O4c9NxQ": "Number_of_copies",
  "kivnyV8XFOA7BaL_b3ZIyw": "Date_Change_Received",
  "kleKXJjYpB-kFhtFi3JNgQ": "Preferred_Greeting",
  "ko9e_IF6pEf1hwOlkipKWg": "Date_Incorporated",
  "kooVr4958BIoGzs8af5A1A": "Other_important_non-source_documents_below_have_been_scanned.",
  "kq8VEiGXBlMJQSrlPMVLLQ": "Middle_Initial_-_Primary_Contact",
  "ksnZAIotmMPtw_MVgNxH_w": "Email_Address_-_Personal",
  "ktMl1sBF1VP1WiGJcTBDsQ": "Position",
  "l4hMVg7tEr6wOVz9zqlByg": "Current_Mailing_Address_-_Zip",
  "lBpyaCF9NCmfV6e0X51B8A": "Notes_from_Salesforce_review",
  "lEMAw3XAb0bbgXliOYFOYQ": "Current_Mailing_Address_-_State",
  "lFrTIkUsVCKG3YOGkwVA-A": "Client_Address",
  "lG_K95qHd6pnksL4_LxAsA": "Date_Change_Received",
  "lGfWMTOmaWVumHs8jXxAtw": "Tax_Return_forms_have_been_printed:",
  "lJoiBJOkOOwdOp_k-TxCoA": "Phone_Number",
  "lNlu7GtPURIpzez7zC1Grg": "Business_Name",
  "lO8SsuOZvmIOcE0FaqNJRw": "Attachment_if_Applicable",
  "lOL1iV0oR6COx14iwMdDsA": "Final_Date_of_Service",
  "lS-4a3SoTC9uZ8m_FsxDTA": "Federal_Withholding_-_Details",
  "lUA-9Eplc8FmzQHWyu9KEg": "description",
  "lWyHDCZaExpUSBuRcaFESw": "Contact-Email",
  "lYKrIVjhti7RGxQydzZNcw": "Spouse-Phone_Number",
  "laqzkrcjxFZGOtaD10xKAA": "Middle_Initial_-_Client",
  "lbBoC38thNrsRuCZ3vZLLg": "Last_Name",
  "lbxGOGdFGDdhvVRv5NlOVA": "Details_of_Update",
  "lcF6zrsMkNvgmP8nxqRPog": "Date:",
  "leF3SH8cxYKR-D_BlDFOig": "Business_Physical_Address_Country",
  "lfxuYrZ_i2vLKD1ZnZNCMg": "CFS_Client_ID_(From_Brain)",
  "ljVKsvcXit5sO-YUIoRBJA": "Business_Name",
  "ljdx3SlSVEVF5-PJWNxKcA": "CSF_Client_ID_(From_Brain)",
  "lk9acUEMRuXm4r_Bz81M6A": "Primary_Contact_CFS_Client_ID",
  "lkPh4_t6yclBuEGh_sRG9w": "Last_Name_-_Client",
  "lkWRE8JtffXGaSuGLlxMew": "First_Name_-_Client",
  "lko5gD5gQVGeZw_H43dIFg": "First_Name_-_Client",
  "ll-IkDW-nwM8pYuUHqVBWg": "State_-_Primary",
  "lmxeGq73dTnIc3jtchxDKw": "Details_of_Update",
  "lnytHVsDy1VI4Z7K1whCjg": "Client_First_Name",
  "loTy8rYEht5_5neDchZNAw": "Business_Start_Date",
  "lr9J10vgq4f1YYaAuPBCvQ": "Zip",
  "lsY9KTqQN9PV6nh5uhlGmg": "First_Name_-_Client",
  "lt4DEeHg2335OiGozUFFTA": "Last_Name_-_Primary_Contact",
  "lxV7crfVdg1R8vXU_cNApw": "CFS_in-house_accountant:",
  "lyK4JlhG8BpF1TUWPr1F2A": "Client_Mailing_Address_-_State",
  "m2GH-5PFzqUub-YkHZpPwg": "Client_-_First_Name",
  "m5WczuPcSzZekdWDObhOfA": "Preparer_to_Respond",
  "m7rK2czMPDwOfx5JTERPWQ": "Contact-SSN",
  "m83sl42tNcm6i6y3jXtAbA": "Attachment_if_Applicable",
  "m8EZfUIXqu5FVv7OXLlM0g": "Accseed_or_other_outsourced_contractor:_2",
  "mF5w2Vsyg8R6L-W8FsZKCQ": "New_Physical_Zip_Code",
  "mMevwu_5ehvLqGuXtn9A9w": "Date_of_Death",
  "mMsMiRz96ckeXDTTmvBF5w": "Date_of_Birth_-_Primary_Contact",
  "mPz68z5B7uJ9fjLHPxlARg": "Completion_Deadline",
  "mQuJZRQc-8T9SibAVU9GYg": "Details_of_Change",
  "mSoI3OP0F6Yq2FEv7ShK4g": "Completion_Deadline",
  "mTy6cbAsw1tnCwGJ0d9ImQ": "Qualified_Account_Number",
  "mV8EQ1HTBqGs8VEVy1FEAg": "Department",
  "mZmfWcF4Fmz9r6VT_f5Nmg": "Effective_Termination_Date_of_Letter",
  "mb7H30o3fRMairbmYp1BcQ": "Client_Last_Name",
  "misXAXycGzjEwFB8Zw5PRA": "Client_Salesforce_ID_(for_Automation)",
  "mkpNcIcjaQPiCnimjnBEOQ": "First_Name_-_Joint_Owner",
  "mpYY2PyTY5cwQxf8JTpPHA": "Desired_Net_Amount",
  "mpZRFFTj4XWMzbIwPsNP4g": "Date_Change_Received",
  "mrEZL5niNZwvCJJ37RBAvA": "Last_Name_-_Client",
  "mrbjZx5kV7CrR8nriDtMQA": "Message",
  "ms6_Xmg9r2Wu35FqpPxJUA": "generation_date",
  "n2hWz-YOcTiB7MCKD_NAig": "Last_Name_-_Client",
  "n3qt1wK0myRsqISinyBHcg": "Attachment_if_Applicable",
  "n4YWnTOW3VLjZg0Ups9IWA": "New_Physical_State",
  "n4o5YRq2e2yQo8bnzeFL8Q": "Date:",
  "n60p6vafX2dkxaVVl9FE5A": "Last_Name_-_Primary_Contact",
  "n6FIAGDBPJ4MOqlv64NI1Q": "Client:",
  "n7RWRSwtL5vnq1uaw1JBRg": "Supporting_Document_2",
  "nBomBaYBT2Gf5E2iFZBKdQ": "Description_of_Termination_Change",
  "nEBFc4RbbXxK2-D3EThFpw": "1040-ES_(Federal_estimated_payment_vouchers):",
  "nG-JH4Ks56PpgQ-j8r5F-A": "Client_State",
  "nJnpUGTU3lLj5BX8gaROyA": "Clients_Driver_Licence_Number",
  "nP9JpcKpGSL67Za9BuJEuw": "Client_Name",
  "nQBVhqmOM9FaCRGEBC1K0w": "Client_Last_Name",
  "nS4Et_NmRhB8SkEeyJ1MXw": "Address",
  "nURjwkpJvnCaBLBMN4xPKw": "Meeting_preparation_checklist",
  "nW7ex2W0jax5_VGMybhOgQ": "State_Withholding_-_Details",
  "nZCqoEuzPRgI1ad93I1KuQ": "Select_who_will_be_completing_the_workflow",
  "nZo1Ta9qcUyknQdME0FK3A": "First_Name_-_Account_Holder",
  "n_6ZY4hA0XtXW2PZI95MUA": "Change_Request_Summary",
  "na0M0rWexJX1zLxHkBFFTg": "Supporting_Document_1",
  "naldATBEfv9X0akYYoJKVw": "Client_Mailing_address_-_City",
  "nfB60UC_U2DgLYRFlmxJYg": "Email_Address",
  "nnMhM4BUsYkEcfUYyDFBhg": "Middle_Name_or_Initial_-_Primary_Contact",
  "nr5OzCLS1032jTop1btMvQ": "SSN_-_Spouse/Partner",
  "numxGh7jtRAYFyFN1D5P8Q": "Roth_IRA_Account_Number",
  "nv701zfaQGKJ16Yx1btDqA": "Change_Request_Details",
  "nw054keVHc50OFeyT7ZDUg": "New_Mailing_Address",
  "nwrQVqIG1gk4hS7Ch2pO9w": "First_Name_-_Executor_2",
  "o-GjnXcOCRbNQa5C2rdBZg": "First_Name_-_Primary_Contact",
  "o0GeztuTs6DIXT3_gj9GBQ": "Last_Name_-_Client",
  "o3r8nECcr2z6vpNUSYBJHw": "Select_who_will_be_completing_the_training",
  "o424BQEDS2CDP1mAokFJxg": "Beneficiary/Trustee_Birthday",
  "o5kMX8hHHmwXrPVUPSdI7Q": "New_Employee_Email_Address",
  "o6ZuPs54s7z4S7kDDIRLNw": "Document_Review_Checklist",
  "o76xRMqAlN6XEMIKvZBO6Q": "CFS_Client_ID_(From_Brain)",
  "o8kEG8_byZ5z1qI2OR5G3g": "First_Name_-_Client",
  "oBd5sqOBi5z-GXGQ2m1KVQ": "Department",
  "oFHo3CtNjXyiE59reiZNSA": "Change_Description_-_What_do_you_want_us_to_do?",
  "oG_BJaPzu4vLerPQ01FB8A": "Spouse_First_Name",
  "oLZoPVasRvVUpst6DvZAwg": "CFS_Client_ID_(From_Brain)",
  "oLefL_Mq7G5HPAYkodxBlA": "First_Name_-_Client",
  "oLurnFdsltmAZbVSY3RLVg": "Proposed_Meeting_date",
  "oOf5WtCw_W2OuaUX6o1Flg": "Attachment_if_Applicable",
  "oQ7r6MSWZVrc_QAmZV5DqQ": "First_Name_-_Client",
  "oRoi0vhiwxaASw7EgBJPxA": "New_Employee_Address_(Zip_code)",
  "oSz7yedZvjiwA6O7Q95PGQ": "Care_of_Contact_Mailing_City",
  "oXEpMT4IBozkkhj5wItGuQ": "Details_of_Update",
  "oXmowTW-eIt99C6rwhhDOg": "Date_Change_Received",
  "o_puJ80CFbYMqcKGtx1MRg": "Client:",
  "oexhHY5oZ-JAOa9lSXtBug": "Meeting_Agenda_Link",
  "ofwFvUvKkFoSdi8MqORAaA": "Email_Address_-_Executor",
  "ohC56yvojbqPdsrcRfdEKA": "Date_of_Birth_-_Spouse/Partner_Information",
  "oicP0M_rQ8aYqVHp769FdQ": "Start_Date",
  "oj4gVQUO1dzBb3bujSpMQg": "Client:",
  "okz4Mab90Yd3vAtr2aBK3Q": "Contact_Name_(If_person_can_not_be_contacted_for_any_reason)",
  "ol7diDP-DU0M4TJ9EsFFCw": "Last_Name_-_Spouse/Partner",
  "onf1FwZcZicIq2ydq1ZIww": "Period_End_Date",
  "opYRNsqpewz8ofor_d5IyQ": "Middle_Name_or_Initial_-_Spouse/Partner",
  "oqOU6TaDguiXfO7RMbJFhg": "If_the_client_has_requested_a_paper_copy_to_be_mailed_as_their_delivery_method_of_choice,_be_sure_to_do_the_following_along_with_other_paper_assembly_steps.",
  "ordstVYl25CKKDr1T1VFiw": "Summary_of_Estimated_Payments:",
  "orgJxXrXYcWR7pxA32FPJA": "Spouse/Partner_CFS_Client_ID",
  "ow9Jh7IRlyZ7DY3cfxpIdA": "State_-_Attorney_2",
  "oxTiK4WSVB14HjEwhqRPtg": "Completion_Deadline",
  "ozPRb2zB2IX-EnFq-LJC_g": "Date_Change_Received",
  "p-F3PXNTrNdEfEeiPkZKoQ": "Referral_Source",
  "p-GVCIXG_GffS6EkyhBBaQ": "Using_the_Avery_5161_template_above,_print_out_a_sheet_of_labels_and_do_the_following:",
  "p0877VNqadi8Ci9IwcNCvw": "Client_Mailing_address_-_Zip",
  "p08zgqPXbQ7zDwZ3iHtNSg": "Client_Salesforce_ID_for_Zapier",
  "p0ndw0leHT5O0QNAnVpAXw": "Purpose_of_Meeting_-_Other",
  "p3E2ZKIvkdSOewGUCrRJtQ": "First_Name_-_Client",
  "p4QZtEg2yXIFm4tqV65OgQ": "Email_Address_-_Primary_Contact",
  "p4fWhfZZPhh6JwnyLrBJjw": "Business_Physical_Address_State",
  "p7MZvFuihbNgOG_GWI9PGQ": "Services_provided",
  "p7N-zsqCxDK1BDw09txE7Q": "Contact-Phone",
  "pA3UClYeycwKNSPkb1pG_w": "First_Name_-_Spouse/Partner",
  "pDXLPcpnxJ5qSi9qy1RNMg": "Email_-_Client",
  "pEECb3fbVRG-VVXiftJBgg": "Attachment_if_Applicable",
  "pF-FjlRfBK8h505qs0BCJg": "SSN_-_Primary_Contact",
  "pF9jojxuiiOOxI0f9-VPOQ": "Additional_Information",
  "pM08iFpgIprDp8aU9xhLOw": "CFS_Client_ID_(From_Brain)",
  "pNyPqZOlgZJlmk67N1dIFQ": "Last_Name_-_Client",
  "pOWoxsiOqdeYJRKDHllPNg": "Email_-_Client",
  "pQlJCHVBwe581QcPUMlGMw": "Occupation_-_Account_Holder",
  "pV63TK7RgMofxKZ0WM1NUA": "Hours_per_week",
  "pWEDN0ymdcQTmVL4SBxApQ": "Birthday",
  "pWTNvJr89hVkPGeE3dlNVQ": "Upload_Dans_Trade_Notes",
  "pb1fhfaHlMHozzvxGK9I1g": "Client_Phone_(Primary)",
  "pcNQEDvuCaPVGZ7-oHNH6w": "Household_Name",
  "pgACe-gw5uSXVHkehqNDdA": "Email_-_Client",
  "pgBf2N3YtnV-TvRTCxxBCA": "Number_of_Employee_Reviews_to_be_Completed",
  "pim3KW3ji7HCdULf1X1HLw": "Services_provided",
  "pkjkgPzjK8yPFaT8U_tL9w": "CFS_Client_ID_(From_Brain)",
  "ply0Kh04ijOtZIzcz7tAoA": "Amount_to_Convert",
  "prfSqrqi8wg4IvnC_MBAag": "Details_of_Marital_Status_Change",
  "psJMi6m7hu7-Ja-7ypRKgA": "Description_of_Email_Change",
  "pvvkvGLtJ2LP_we17OVGww": "New_Employee_SSN",
  "pwznRMUw0EcwCp28hyFE1A": "Last_Name_-_Client",
  "py5QM_-EBvx7kQKOz-dBMQ": "Who_is_making_the_request",
  "pzyWtF6HmwiHUZkwrDJKvQ": "Contact-Birthday",
  "q-DLb3LGq1Mr5qm1VQNHwg": "Household_Email_Address",
  "q-oxeMGmjsr8iNScz6NOOw": "State_-_Executor_2",
  "q06ejevLyT8cq5jhSHhCdA": "Date_of_Marital_Status_Change",
  "q1VAh0jMqYHt5Xsf_SdBhg": "Beneficiary/Trustee_Phone_Number",
  "q1cfL0MPZNqXUjw8wNZC_g": "CFS_Client_ID_(From_Brain)",
  "q2McaRInfrAV9LqGlWlNxQ": "Last_Name_-_Client",
  "q2yWCRxVwqrHBR2LL2dJPA": "invoice_description",
  "q302TH0RkLC_b7p5xd5K3g": "Last_Name_-_Employee",
  "q3JW29AuWJdnGhN2B-NP3A": "Date_Change_Received",
  "q78ao1uFFcJgYJkVZLxBiA": "CFS_Client_ID_(From_Brain)",
  "q7uEDuS13gvqTKFrx1dCVw": "Void_Check_or_Bank_Statement",
  "q8LqXpji72f5NyDOy21F6g": "Primary_Phone_Number",
  "q9Q9jCZxe86BWDLarBtKyw": "Beneficiary/Trustee_Email_Address",
  "qBpHuwrgk14AL3V1GtBNGQ": "Date_Change_Received",
  "qDSblcYvqYUlbK9zhTVK0g": "Last_Name_-_Client",
  "qHz-4BVmZXpJe5dlsRdAZA": "Middle_Initial_-_Spouse/Partner",
  "qIZLiFrC_PxjfRETJRNOew": "Client_Last_Name",
  "qKDsmpO-Ckz012YdcgVH_g": "Business_Phone_Number",
  "qKM0TBUW7fatZvglGENI9Q": "Last_Name_-_Primary_Contact",
  "qKe3jO2dXfxUH7ArrJpPOA": "Contact-Address",
  "qUc-jMjK4i-9GLINZ4RO8Q": "First_Name_-_Spouse/Partner",
  "qUjrwEgxqisU9T0J5-NNXg": "Primary_Preferred_Greeting",
  "qaKUMbzPOyJwThta5zVJ4Q": "Beneficiary/Trustee_Last_Name",
  "qbj7QIyCXU3kY144ExNFtQ": "Email_Address",
  "qcdkeVEhjsaN11QZfERLzQ": "CFS_Client_Household_ID_(From_Brain)",
  "qobk_7lpXVWKwWSdWydPTw": "Client:",
  "qpkPwk35_Uh0CjRPjDFGpw": "Additional_Phone_Information",
  "qpy8b4H4hMYWmyNez6tKig": "Message",
  "qr6UubPuQ2kGQPrFX5FIAA": "New_Mailing_Zip_Code",
  "qrlnQRqgYfW9soMLX3BAjg": "Additional_Information",
  "qtl6q1JTdwu02MPq4mlFqg": "Last_Name_-_Client",
  "r-fiMQGFqExPW2QmNE5Hfg": "Client_First_Name",
  "r0UJpezoRrVUUqpkIA9HQg": "EIN",
  "r2IGLkgUoHqC0k5UiQtELw": "Last_Name_-_Attorney_2",
  "r4onwtbU-QSkPwSMd_pF1Q": "New_Employee_Address_(City)",
  "r4wxQFYlN5VEp2Y0CslAeg": "First_Name_-_Attorney_2",
  "rCEsX3ueZ8M5_zTNCAFGwA": "Client_Mailing_Address_-_Zip",
  "rDceZY8jbgOwaS81YsdNGA": "Date",
  "rLM9fGhHcE8DAcJjm3FFrA": "Company_Name",
  "rM2i8FJ5VlQ-9hF55wBHEw": "Care_of_Contact_First_Name",
  "rOwyR52SKw-SvK138xlDIw": "Last_Name_-_Client",
  "rPgWBzT15zFPMAPWmhtPwQ": "Email_Address_-_Attorney",
  "rR7uM_GJ3oTpO2HqlctGdg": "First_Name_-_Client",
  "rSWjN09j0960f9qx8S5AXw": "SSN_-_Primary_Contact",
  "rT-ZcG0rcrPffYh9_e1CMQ": "New_Email_Address",
  "rZ1YxowfoKQcNERxQnVO1w": "Signature_Documents:",
  "raPoCCXOmxbLX2Etwk5PgA": "Care_of_Contact_Mailing_State",
  "rc9SJnV205kyD165UO5LqA": "Start_Date",
  "reJIWwyafgt2mZln4y5FNw": "Period_Start_Date",
  "rg976OG3-ecclqB3wi5Img": "Last_Name_-_Spouse/Partner",
  "ri6h25g0xGUn_hiupXJJSA": "Last_Name_-_Spouse/Partner",
  "rjcor4aiBz0PTvo0SyROTA": "Last_Name_2",
  "rlJ2-3ihcXH3ZOnGYtNCfg": "Phone_Number_-_Executor_2",
  "rltfAGcaNmUVGW9QJEhJpg": "EIN",
  "ro24v0J_WO6fUcXK2FdDNQ": "send_email_3",
  "roNS1R9hFkOfEWyDfi1ATA": "Date_Change_Received",
  "rpk6-OT7qX740uhMKqJBCA": "Date_of_Birth_-_Primary_Contact",
  "rpxE_FfRixAZ4YQ6HEdMXA": "Primary_Clients_First_Name",
  "rqzx3pAVG6Ot7XeEszdB3Q": "Client_First_Name",
  "rv32hOj1inYCK-EWfrlK7g": "Fiduciary_Name_(Title_on_return)",
  "rvNl7cVDsQrricjMPFhMFA": "CFS_Client_ID_(From_Brain)",
  "rweHn8mB3tDhqFU-JEFHnQ": "Stamping_date",
  "rz-I1-eQ6HdN1oEmp-RBmg": "Client_Mailing_address_-_Street",
  "rzWpqNRCZqYfEd91S31IHg": "Email_-_Client",
  "s0O_fHONGQYicqmUBBpFug": "Date_Change_Received",
  "s1Pd2w0xVJwhy-WDa_BCEA": "Business_Mailing_Address_Country",
  "s2-w8e7G5mC9Qy-H5bxMJQ": "Date_Change_Received",
  "s3K0YxPzQRVc357geSBB7g": "Referral_Source",
  "s3KoE90CC-eZJNhgXpdH8g": "Last_Name_-_Client",
  "s5cXLdp7guIY07TndF9B5g": "Date_of_Birth_-_Spouse/Partner_Information",
  "s5fZQU77pXbAp88PZS9DPg": "CFS_Client_ID_(From_Brain)",
  "s5pw7dOI932I1pOdU9lCoA": "Street_Address_-_Executor_2",
  "s6QYNPBwnCWTcybtUX5FyA": "Description_of_Termination_Change",
  "sAbl-7dhhGArZdAbxwxCaQ": "Primary_Clients_Last_Name",
  "sAs7NxP2g7Tyt9BC3m1CSQ": "On_the_left_hand_side_of_the_return_folder_should_be_the_following_items_in_this_order_(top_to_bottom):",
  "sFl1uP4rq7KjwCP96nJEzg": "First_Name",
  "sGdJd_xccEmGIZSKFEpOhg": "Client:",
  "sHV5A1XeNenPJwBt-mZFVQ": "City_-_Primary",
  "sJ2BKHH9QnCM-1USeg9J0g": "Workflow_Name",
  "sLtbDqXOKzhjsSyyyXZGFQ": "CFS_Client_ID_(From_Brain)",
  "sLuIfq1AMcECFb1AQYtDEw": "Care_of_Contact_Mailing_Street",
  "sQfJJlONJqqvgSgFdHRILg": "Client_-_Last_Name",
  "sRmLHW93BiT0FUwp40JEYA": "Phone_-_Client",
  "sTabSj0iuvhQmYlJ6khACw": "Attachment_if_applicable",
  "sVOT9Ph2STX5RcAZ_GNPDQ": "Date_of_Birth_-_Spouse/Partner_Information",
  "sViiKxqqpIgLiUojoWZODg": "Business_Mailing_Address_City",
  "sVywYMfFQD4E1D2MDGlDXw": "Contact-Address",
  "sXeYTgmFO1w-1ShWD7BCdQ": "Date_Change_Received",
  "sZCQSJk68NXcekFRDcNG-A": "State_-_Primary",
  "sejRTbiA7c1O7-XG4PdKbQ": "Client_-_Last_Name",
  "siVNTkz0bcbSsTf0p_BI7w": "Last_Name_-_Client",
  "spqVtGvzZ0sUj_CErDJE3g": "Client_Email",
  "srUM6n7aZY_hB1Q3JadHFg": "Fiscal_or_Year_end_(_with_dates_for_fiscal_yr_and_due_dates)",
  "ssqJkmFD8IwHTnWvQBxHxA": "Spouse_Last_Name",
  "st3jSiMPuIT_ABq14vRKwg": "Separate_original_documents_(to_go_back_to_client)_and_copies_of_source_documents.",
  "stVhiwSp1A587hd1UIRBJg": "Date:",
  "su-S4BP-WgmlL9Z7qghBJA": "Last_Name_-_Client",
  "sy4DsIC9TKJYTv6jS_dPFA": "Link_to_updated_workflow",
  "szacR9_4kh5ne_jnu9dPkA": "First_Name_-_Spouse/Partner",
  "t4YLaws0DhiU1xoWJnhN_Q": "Steps_for_Preparing_for_a_Business_Tax_Return_2",
  "t4aYJDZJTpqJa3jCrpJBAg": "Date_of_Birth_-_Account_Holder",
  "t58gNYVh76qegzX-XwpBlg": "New_Employee_First_Name",
  "t6esvw8jELEBnmHq2-pJrA": "New_Employee_Address_(House_No_&_Street)",
  "t6utTxo5nq_-YZ_zdeRMZA": "CFS_Client_ID_(From_Brain)",
  "tHQGqrNT-j1mcsyrx4hIsA": "Business_Mailing_Address_State",
  "tHd8rbmXL5AX4_IV-ghNjg": "Employee_Start_Date",
  "tLxmk_3sHna21R4nf4tPhg": "Date_Change_Received",
  "tS887rkobs2qxI8fNJ1P2g": "Date_of_Birth_-_Spouse/Partner_Information",
  "tUd38VYx3EnvrxUBVv9JVw": "Additional_Household_Members/Business_related_to_Phone_Change",
  "tVCCyFr7Q2zbTvbevYBJWQ": "First_Name_-_Spouse/Partner",
  "tXFNZ-XoP4AH2r3aza1Oug": "Care_of_Contact_Physical_Street",
  "tXOHJMiA_YIYQCp7t4tEtg": "Middle_Initial-_Spouse/Partner",
  "tZAwEIr1MQRBLFlXCllI2Q": "Last_Name_-_Client",
  "t_0xYrWVoDWKeEp0th1JLA": "Client_Salesforce_ID",
  "t_8us8vS3rxtPYXQM85FtQ": "Household_Email_Address",
  "tblFHaZVZc_x5F574PdHUg": "New_Employee_Address_(State)",
  "tbyxM5pjERdA5-AuzoFKig": "Social_Security_Number",
  "tds2t1eE-Lx03hhfJ4pBPA": "Go_through_the_clients_green_file_folder_and_scan_any_documents_that_have_been_used_in_the_tax_return._Merge_tax_documents_into_the_client_binder_following_the_Tax_Sorting_Guide_for_A,_B,_C,_D,_and_E_Documents._documents,_and_bookmark_them_appropriately.",
  "te1mFuUH7jfyYyMCWSlEUw": "Beneficiary/Trustee_SSN",
  "tfZcFgHUXMPspT3TtEFEHg": "Copy_of_ID",
  "tfryjHnT6DDu4vLXLIlGeg": "New_Hire_Paperwork",
  "tkxQF8AyGCm-PJTAOs1Jxw": "First_Name_-_Primary_Contact",
  "tlDjBkXtURnLyz2WE5lEOA": "Phone_Number_-_Attorney_2",
  "tlSH6C1lZde0-cbBIEVMZw": "First_Name_-_Client",
  "tnW5-45XOAfxc-IP6YxM4Q": "Primary_Phone_Number",
  "to4-xeyyQfZNJXqDXwtAbw": "Client_Last_Name",
  "toVdrNZhHYiAUv6LizRAFA": "First_Name_-_Client",
  "tqi0Z6HZSNbBn9SLBpJHpQ": "First_Name_-_Client",
  "ttfSlmFnSFlAkLRk5e9FVw": "Phone_Number_-_Primary_Contact",
  "tw5yIZTyb5IR1vt2LLVNSQ": "Household_Name",
  "txToeis-44TzmzGLBTVHwQ": "Review_date",
  "tzdkx7tFOB1_s6OS2hVCTw": "Date_Change_Received",
  "u62uTQG1D4XGaXLZVr1KzA": "email_2",
  "u7g04ubE5yrfxbvKoW5JpA": "Phone_Number_-_Primary_Contact",
  "u82TUxW1QACveXX9ELJI0w": "Details_of_Update",
  "uCKiHQNFEJm4LhOWabxFog": "New_Mailing_City",
  "uDRdqjtOnwR61nCKCXhIkA": "First_Name_-_Employee",
  "uDm9q0VhmtRLgUjFuEJEkw": "Select_who_will_be_completing_the",
  "uGJEyG-zm7Ubfu1HcWtL5Q": "Client_Mailing_Address_-_City",
  "uGm9TdU7yPhN0EZkbplIow": "social_security_number",
  "uJTNK5QPWWOZzwVTZbJIPw": "Invoice:",
  "uMlx9fAZYxfOUylF3hxKaw": "CFS_Client_ID_(From_Brain)",
  "uOhYf9pjyFCi8QvjottHUw": "CFS_Client_ID_(From_Brain)",
  "uP9g6q0RAfo94IlrQWlAEw": "Current_Mailing_Address_-_City",
  "uQl-1n9bHJbv4uD5dNNN2A": "First_Name_-_Client",
  "uQtQji_ans1Mt6e28G1OBA": "target_start_date",
  "uRO0XP7hgxGVv03U17lBPA": "Date_of_Birth_-_Primary_Contact",
  "uSlOZ2_dh_wUBNttoJ5C0g": "First_Name_-_Client",
  "uU0brPDVTSb6MJ6AAF9GgQ": "Attachment_if_Applicable",
  "uUT8FBSy--LJ5erLU1lMOg": "Last_Name_-_Client",
  "uYumHf2OJAUeMNv74yNPfQ": "Phone_Number",
  "ub0iHfiy7-NayjuAojBEvQ": "id",
  "ubXWI3zX_iPCLiuHVLJF4w": "Deceased_Client_Information",
  "ucmeSJecYnqXYtS9Q0FG9A": "Client_First_Name",
  "ueA06y57CS5GUIbpMolJCA": "Position_Name",
  "ufBCA-b72T9Ngmk9I3JDRA": "Account_Number",
  "ufqCK-v-xwXrmBHLw4xA9Q": "Date_Change_Received",
  "ugO3tfm751HdngO5o0lA5g": "POA_Form",
  "uggk93UIwALp70MOtctJlA": "Using_the_Cream_Presentation_Labels_template_above,_do_the_following:",
  "uhLhPFimGcZms-dlcCdNXw": "Additional_Critical_Information",
  "uj006ixXy-sOW1O8EKxG0Q": "New_Phone_Number",
  "umJDS3ik-f0mEUsXSyNJ9g": "First_Name_-_Client",
  "umsVDCLc8GS0Cr_MPZxBlA": "IT-2105_(NYS_estimated_payment_vouchers):",
  "uoAz3eisKXCjNu_hNbhPPQ": "Supporting_Document_3",
  "uozxJrWrEowLFhKr1TRClw": "Clients_Saleforce_ID",
  "upQdU9tkzzQbmoVTGwVPUg": "Workflow_Template_URL",
  "uu_lu0Jn58BpSyVKCwRE9A": "Client_Salesforce_Account_ID_(For_Automations)",
  "uvMygWrDSfWDbksWzPVA6Q": "Business_EIN",
  "uvz5Ir0H3STTgWsAQ-NKzA": "Beneficiary/Trustee_Address",
  "uyo4va0akJAxlzyp90BNhQ": "CFS_Client_ID_(From_Brain)",
  "uzFO3ZpfHcw6O2tddTtBog": "CFS_Client_ID_(From_Brain)",
  "uzflCTCFF6UJE8oLUp5AKQ": "Attachment_of_Marriage_Certification_or_Divorce/Separation_Papers",
  "v1hTnBPZShOmuQ9APQ9FaA": "Business_Physical_Address_City",
  "vAB9vDtcFAUYGpFRyGNFQw": "First_Name_-_Client",
  "vCBAIyi1evVLtS1ZEwZIrg": "CFS_Client_ID_(From_Brain)",
  "vCt385Ou25kFRrYrxhhEqQ": "CFS_Client_ID_(From_Brain)",
  "vD0SK1HorWFnKTQwnCtMgQ": "Business_Mailing_Address_Street",
  "vJjvdLZMM_nHgRHLQNZAAw": "Contact_Name_(If_person_can_not_be_contacted_for_any_reason)",
  "vMTr4uopI5PdlIdLQslAFQ": "Date:",
  "vMkWfWQRcPyQgtiRI0JF3w": "Last_Name_-_Primary_Contact",
  "vO0nBdi_I9LD-yMYDSJGgQ": "Client_Last_Name",
  "vPIsit_8E0rzSAsBTEVLMg": "Who_is_the_client?_2",
  "vQ-mp3WWD6RWKCoB8b1O_Q": "Primary_Clients_phone_number",
  "vRK7c_h8XqTH9lildD5LqQ": "Something_Else_-_Details",
  "vTaN00NKt9ukpefkOw9MoA": "First_Name_-_Client",
  "vUJkj73NUpmqmeDWeghKmQ": "City_-_Attorney_2",
  "vVARqcB1PnwhHoBrBuNJPA": "CSF_Client_ID_(Cuddy_Brain)",
  "vVdxilar7IN2u7aUttdCtQ": "Contact-Email",
  "vWNKkL1oW0d2Xo-NOzdOfg": "Referral_Source",
  "vXOHW_hQn7c_LSH_L1ZMdw": "New_Employee_Date_of_Birth_(mm/dd/yyyy)",
  "v_Q1U_t6W9EKMISgwEdGTA": "Address_-_Primary",
  "va6FWxF8n_yT9dx8IRBHZA": "Related_Documents",
  "vcavM0kkKGPokU_gGg5C8w": "New_Mailing_State",
  "vhA6aCGzl7w6ftIwffxFBw": "First_Name_-_Client",
  "vi9zWTDRtNS7Nkpz_eNEoA": "First_Name_-_Client",
  "vj0uHJdA5AY22ODy9-5K5Q": "Business_Mailing_Address_Zip",
  "vmDhvFnIF8r9kSvb_dRC5Q": "CFS_Client_ID_(From_Brain)",
  "vmFkAotfT-MVpkGgLnFD9Q": "Address_-_Primary",
  "vodXhz6i1SgOQ_d2TdFA4g": "First_Name_-_Primary_Contact",
  "votnqmZq_GQ59MeJnsFFGg": "Distribution_Reason",
  "vpax1A-5nS70A7neC2JFEg": "Client_Email",
  "vqMfXu7c2g_y8MnHGwNMZg": "Client_City",
  "vqOk-PMxObIWT9AQPNJDoQ": "Updated_First_Name",
  "vwts7W67LhiXocTAWf5H1w": "Street_Address_-_Attorney_2",
  "vxn6Y4GvWZuKzqDN6b5H5A": "Details_of_Update"
}

const formatDateISO = (date: any) => {
  return date ? new Date(date).toISOString() : "";
};

const formatAddress = (address: any) => {
  if (!address) return "";
  const {
    street = "",
    city = "",
    state = "",
    postalCode = "",
    country = "",
  } = address;
  return `${street}, ${city}, ${state} ${postalCode}, ${country}`;
};

export const generateDataFromFields = (
  customer: ICustomer,
  spouseContact: ICustomer | undefined,
  primaryContact: ICustomer | undefined,
  familyContact: ICustomer | undefined
) => {
  const data = Object.keys(fieldIdMap).map((key) => {
    const id = key as keyof typeof fieldIdMap;
    const fieldName = fieldIdMap[id];
    let value:string | Date = "";

    switch (fieldName) {
      case "Client_Type":
        value = customer.customer_type || "";
        break;
      case "First_Name_-_Client":
        value = customer.first_name || "";
        break;
      case "Client_First_Name":
        value = customer.first_name || "";
        break;
      case "Last_Name_-_Client":
        value = customer.last_name || "";
        break;
      case "Client_Last_Name":
        value = customer.last_name || "";
        break;
      case "Preferred_Greeting":
        value = customer.greeting || "";
        break;
      case "Birthday":
        value = formatDateISO(customer.birth_date) || "";
        break;
      case "Date_of_Birth_-_Primary_Contact":
        value = formatDateISO(customer.birth_date) || "";
        break;
      case "Age":
        value = customer.age?.toString() || "";
        break;
      case "Client_Email":
        value = customer.email || "";
        break;
      case "Email_-_Client":
        value = customer.email || "";
        break;
      case "Email_Address_-_Primary_Contact":
        value = customer.email || "";
        break;
      case "Mobile_Phone_-_Primary_Contact":
      case "Primary_Phone_Number":
        value = customer.primary_phone || "";
        break;
      case "Client_Phone_(Work/Business)":
      case "Work_Phone":
        value = customer.work_phone || "";
        break;
      case "Home_Phone":
        value = customer.home_phone || "";
        break;
      case "Address_-_Primary":
      case "Mailing_Address":
        value = formatAddress(customer.mailing_address) || "";
        break;
      case "Physical_Address":
        value = formatAddress(customer.physical_address) || "";
        break;
      case "Client_Rating":
        value = customer.rating || "";
        break;
      case "SSN_-_Primary_Contact":
        value = customer.ssn || "";
        break;
      case "CFS_Client_ID":
        value = customer.cfs_client_id || customer.new_cfs_client_id || "";
        break;
      case "CSF_Client_ID_(Cuddy_Brain)":
        value = customer.cfs_client_id || customer.new_cfs_client_id || "";
        break;
        case "CFS_Client_ID_(From_Brain)":
          value = customer.cfs_client_id || customer.new_cfs_client_id || "";
          break;
          case "Primary_Contact_CFS_Client_ID":
          value = primaryContact?.cfs_client_id || primaryContact?.new_cfs_client_id || "";
          break;
          case "Spouse/Partner_CFS_Client_ID":
            value = spouseContact?.cfs_client_id || spouseContact?.new_cfs_client_id || "";
            break;
      case "Client_Salesforce_ID_(Zapier)":
      case "Client_Salesforce_ID":
        value = customer.salesforce_contact_id || "";
        break;
      case "Spouse_First_Name":
        value = spouseContact?.first_name || "";
        break;
      case "Spouse_Last_Name":
        value = spouseContact?.last_name || "";
        break;
      case "Spouse_Email_Address":
      case "Spouse-Email_Address":
        value = spouseContact?.email || "";
        break;
      case "Spouse-Phone_Number":
        value = spouseContact?.primary_phone || "";
        break;
      case "Spouse_Preferred_Greeting":
        value = spouseContact
          ? `${spouseContact.gender === "Male" ? "Mr." : "Ms."} ${
              spouseContact.first_name
            }`
          : "";
        break;
        case "Date_Change_Received":
            value = new Date();
        break;
        
      case "Household_Name":
        value = customer.full_name || "";
        break;
      case "Household_Email_Address":
        value = primaryContact?.email || "";
        break;
      case "Household_Phone_Number":
        value = primaryContact?.primary_phone || "";
        break;
      case "Primary_Clients_First_Name":
        value = primaryContact?.first_name || "";
        break;
      case "Primary_Clients_Last_Name":
        value = primaryContact?.last_name || "";
        break;
      case "Primary_Clients_Email_Address":
        value = primaryContact?.email || "";
        break;
      case "Number_of_Children":
        value = customer.dependent_children
          ? customer.dependent_children.length.toString()
          : "0";
        break;
      case "Business_Name":
      case "Company_Name":
      case "Legal_Business_Name":
        value = customer.full_name || "";
        break;
      case "EIN":
        value = customer.ein || "";
        break;
        case "Current_Mailing_Address_-_Street":
            value = customer.mailing_address.street || "";
        break
        case "Current_Mailing_Address_-_City":
            value = customer.mailing_address.city || "";
        break
        case "Current_Mailing_Address_-_State":
            value = customer.mailing_address.state || "";
        break
        case "Current_Mailing_Address_-_Zip":
            value = customer.mailing_address.postalCode || "";
        break
      case "Business_Start_Date":
    
      case "Incorporated_Date":
      case "Date_Incorporated":
        value = formatDateISO(customer.business_start_date) || "";
        break;
      case "Business_Physical_Address_Street":
        value = customer.physical_address?.street || "";
        break;
      case "Business_Physical_Address_City":
        value = customer.physical_address?.city || "";
        break;
      case "Business_Physical_Address_State":
        value = customer.physical_address?.state || "";
        break;
      //   case "Business_Physical_Address_Zip":
      //     value = customer.physical_address?.postalCode || "";
      //     break;
      case "Business_Mailing_Address_Street":
        value = customer.mailing_address?.street || "";
        break;
      case "Business_Mailing_Address_City":
        value = customer.mailing_address?.city || "";
        break;
      case "Business_Mailing_Address_State":
        value = customer.mailing_address?.state || "";
        break;
      case "Business_Mailing_Address_Zip":
        value = customer.mailing_address?.postalCode || "";
        break;
      case "Business_Phone_Number":
        value = customer.work_phone || "";
        break;
      case "Business_Type":
        value = customer.business_classification || "";
        break;
      case "Services_provided":
        value = customer.services_provided?.join(", ") || "";
        break;
      case "Marital_Status":
        value = customer.marital_status || "";
        break;
      case "Client_Phone_Number":
        value = customer.primary_phone || "";
        break;
      case "Client_City":
        value = customer.mailing_address?.city || "";
        break;
      case "Client_State":
        value = customer.mailing_address?.state || "";
        break;
      case "Client_Zip":
        value = customer.mailing_address?.postalCode || "";
        break;
      default:
        value = ""; // Default value for unhandled cases
    }

    return { id, value };
  });

  return data.filter((item) => item.value !== "");
};
