// src/components/Providers.tsx
import React, { ReactNode, useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { ThemeProvider, CssBaseline, CircularProgress, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Guard from '../guard/Guard';
import { inTeams, useExchangeTeamsToken } from '../utils/auth';
import { getCookie } from '../utils/cookie';

interface ProvidersProps {
  children: ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return (
<>
      <CssBaseline />
        <TeamWrapper>
          <Guard>
            {children}
          </Guard>
        </TeamWrapper>
      </>
  );
};

const TeamWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { mutateAsync: exchangeToken } = useExchangeTeamsToken();
  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState<string | null>(null);

  useEffect(() => {
    setup();
  }, []);

  async function setup() {
    const token = getCookie('access_token') || localStorage.getItem('access_token');
    if (!inTeams() && !token) {
      console.log('Not connected and not running inside Microsoft Teams, redirecting to /auth');
      navigate('/auth');
      return;
    }

    try {
      console.log('Running inside Microsoft Teams');
      await microsoftTeams.app.initialize();
      const context = await microsoftTeams.app.getContext();
      console.log('Teams context:', context);
      try {
        const token = await microsoftTeams.authentication.getAuthToken({
          resources: ['api://brain.cuddyfinancial.com/9e254095-c5a3-41c8-8da1-15bdaaab2210'],
        });
        await handleTokenExchange(token, context);
      } catch (error) {
        console.error('Error retrieving auth token:', error);
      }
      if (context.page.frameContext === microsoftTeams.FrameContexts.settings) {
        console.log('In settings context');
      } else {
        console.log('Not in settings context, skipping configuration logic');
      }
    } catch (error) {
      console.error('Error during setup:', error);
    }
  }

  async function handleTokenExchange(token: string, context: any) {
    try {
      await exchangeToken(
        {
          token,
          state: JSON.stringify({ team_id: context.team?.groupId }),
        },
        {
          onSuccess(data) {
            localStorage.setItem('access_token', data.token);
            const customerId = data.customer_id;
            setCustomerId(customerId);
            setupSaveHandler(customerId);
            console.log('Teams SDK initialized');
            microsoftTeams.app.notifyAppLoaded();
            microsoftTeams.app.notifySuccess();
          },
          onError(error) {
            console.error('Error during token exchange:', error);
          },
        }
      );
    } catch (error) {
      console.error('Error during token exchange:', error);
    }
  }

  function setupSaveHandler(customerId: string | null) {
    const contentUrl = customerId
      ? `${window.location.origin}/dashboard/customers/${customerId}`
      : window.location.href;
  
    // If there is a customerId, set the configuration automatically
    if (customerId) {
     
    } else {
      microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
        microsoftTeams.pages.config
          .setConfig({
            entityId: 'Dashboard',
            contentUrl: contentUrl,
            suggestedDisplayName: 'Cuddy Brain',
            websiteUrl: contentUrl,
          })
          .then(() => {
            console.log('Configuration set successfully');
            saveEvent.notifySuccess();
          })
          .catch((error) => {
            console.error('Error setting config:', error);
            saveEvent.notifyFailure(error.message);
          });
      });
    }
  
    // Set the validity state to indicate the page is ready for configuration
    microsoftTeams.pages.config.setValidityState(true);
  }
  

  return <>{children}</>;
};

export default Providers;
