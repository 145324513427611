// src/components/WorkflowCard.tsx

import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
  IconButton,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface WorkflowCardProps {
  workflow: any; // Adjusted to accept the workflow run with tasks
  dueDate?: Date | null;
  workflowUrl: string;
}

const WorkflowCard: React.FC<WorkflowCardProps> = ({
  workflow,
  dueDate,
  workflowUrl,
}) => {
  // Extract tasks from the workflow run
  const tasks = workflow.tasks || [];

  // Calculate total and completed tasks
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(
    (task: any) => task.status === "Completed"
  ).length;

  // Calculate progress percentage
  const progress = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;

  return (
    <Card
      sx={{
        minWidth: 275,
        marginBottom: 2,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: "#f5f5f5",
      }}
    >
      <CardContent>
        {/* Header with Workflow Name and Open Icon */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div" fontWeight="bold">
            {workflow.name || "Unnamed Workflow"}
          </Typography>
          <IconButton
            size="small"
            href={workflowUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Open Workflow"
          >
            <OpenInNewIcon />
          </IconButton>
        </Box>

        {/* Description */}
        {workflow.description && (
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {workflow.description}
          </Typography>
        )}

        {/* Due Date */}
        <Box mt={1}>
          <Typography variant="body2" color="textSecondary">
            <strong>Due Date:</strong>{" "}
            {dueDate
              ? new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(dueDate)
              : "No due date"}
          </Typography>
        </Box>

        {/* Progress Bar */}
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            <strong>Progress:</strong> {completedTasks} / {totalTasks} tasks
            completed
          </Typography>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ mt: 1, height: 10, borderRadius: 5 }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default WorkflowCard;
