import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  TableContainer,
  TableBody,
  TableRow,
  Table,
  Paper,
  TableCell,
  TableSortLabel,
  TableHead,
  TablePagination,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import dayjs, { Dayjs } from 'dayjs';
import { fetchTimeEntriesByUserName } from '../utils/customer/queries';
import useDebounce from '../hooks/useDebounce';
import { useGetUser } from '../hooks/useUser';

// Define the component props
interface HarvestTimeEntriesProps {
  userName: string | undefined;
}

// Define the TimeEntry interface
interface TimeEntry {
  id: number;
  projectId: string;
  hours: number;
  notes: string | null;
  client: { name: string };
  user: { name: string };
  task: { name: string };
  totalBillableHoursForProject: number;
  billable: boolean;
  spent_date: string;
}

// Define the table columns
const columns = [
  { id: 'projectId', label: 'Project ID', sortable: true },
  { id: 'hours', label: 'Hours', sortable: true },
  { id: 'notes', label: 'Notes', sortable: false },
  { id: 'client.name', label: 'Client', sortable: false },
  { id: 'user.name', label: 'User', sortable: false },
  { id: 'task.name', label: 'Task', sortable: false },
  {
    id: 'totalBillableHoursForProject',
    label: 'Total Billable Hours/Project',
    sortable: true,
  },
  { id: 'billable', label: 'Billable', sortable: true },
  { id: 'spent_date', label: 'Updated Date', sortable: true },
];

const HarvestTimeEntries: React.FC<HarvestTimeEntriesProps> = ({ userName }) => {
  const { data: user } = useGetUser();
  const [timeEntries, setTimeEntries] = useState<TimeEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [billable, setBillable] = useState<string | undefined>(undefined);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]); // Filter by date range
  const [page, setPage] = useState<number>(0); // Current page (zero-based index)
  const [pageSize, setPageSize] = useState<number>(10); // Page size
  const [total, setTotal] = useState<number>(0); // Total records
  const [sortField, setSortField] = useState<string>('createdAt'); // Default sort key
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // Default sort order

  // Debounced search value
  const debouncedSearch = useDebounce(search, 500); // 500ms debounce

  // Fetch data when dependencies change
  useEffect(() => {
    setPage(0); // Reset to first page when search or filters change
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, debouncedSearch, billable, dateRange, sortField, sortOrder]);

  // Fetch data function
  const fetchData = async () => {
    setLoading(true);
    try {
      const queryParams: any = {
        search: debouncedSearch,
        billable: billable ? billable === 'true' : undefined,
        startDate: dateRange[0]
          ? dayjs(dateRange[0]).format('MM-DD-YYYY')
          : undefined,
        endDate: dateRange[1]
          ? dayjs(dateRange[1]).format('MM-DD-YYYY')
          : undefined,
        sort: sortField,
        order: sortOrder.toUpperCase(),
        page: page + 1, // Assuming your API expects 1-based page numbers
        pageSize,
      };
      console.log('Query Params:', queryParams);
      const data = await fetchTimeEntriesByUserName(user?.name, queryParams);
      setTimeEntries(data.records);
      setTotal(data.total);
    } catch (error) {
      console.error('Error fetching time entries:', error);
      // Optionally, display an error message to the user
    } finally {
      setLoading(false);
    }
  };

  // Handle sorting
  const handleSort = (field: string) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortField(field);
    setSortOrder(isAsc ? 'desc' : 'asc');
  };

  // Handle table pagination change
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Render cell values based on field
  const renderCellValue = (row: TimeEntry, field: string) => {
    const fieldParts = field.split('.');
    let value: any = row;

    for (const part of fieldParts) {
      value = value ? value[part] : null;
    }

    switch (field) {
      case 'notes':
        return value || 'None';
      case 'totalBillableHoursForProject':
        return value ? value.toFixed(2) : '0.00';
      case 'billable':
        return value ? 'Yes' : 'No';
      case 'spent_date':
        return value ? dayjs(value).format('MM/DD/YYYY') : 'N/A';
      default:
        return value !== null && value !== undefined ? value : 'N/A';
    }
  };

  return (
    <Box>
      {/* Filters and Search */}
      <Box sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          {/* Search Input */}
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ border: 'none' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* Billable Filter */}
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth variant="outlined">
              <Select
                value={billable || ''}
                onChange={(e) => setBillable(e.target.value || undefined)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === '') {
                    return <em>Billable</em>;
                  }
                  return selected === 'true' ? 'Yes' : 'No';
                }}
                inputProps={{ 'aria-label': 'Billable' }}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Start Date Picker */}
          <Grid item xs={12} sm={3}>
            <DatePicker
              label=""
              value={dateRange[0]}
              onChange={(newValue: Dayjs | null) =>
                setDateRange([newValue, dateRange[1]])
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                  placeholder: 'Start Date',
                  InputLabelProps: { shrink: false },
                },
              }}
            />
          </Grid>

          {/* End Date Picker */}
          <Grid item xs={12} sm={3}>
            <DatePicker
              label=""
              value={dateRange[1]}
              onChange={(newValue: Dayjs | null) =>
                setDateRange([dateRange[0], newValue])
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                  placeholder: 'End Date',
                  InputLabelProps: { shrink: false },
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Loading Indicator */}
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Table */}
          {timeEntries.length > 0 ? (
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          sortDirection={
                            sortField === column.id ? sortOrder : false
                          }
                        >
                          {column.sortable ? (
                            <TableSortLabel
                              active={sortField === column.id}
                              direction={
                                sortField === column.id ? sortOrder : 'asc'
                              }
                              onClick={() => handleSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          ) : (
                            <Typography variant="body2" fontWeight="bold">
                              {column.label}
                            </Typography>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {timeEntries.map((row) => (
                      <TableRow key={row.id}>
                        {columns.map((column) => (
                          <TableCell key={column.id}>
                            {renderCellValue(row, column.id)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Pagination */}
              <TablePagination
                component="div"
                count={total}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[5, 10, 25, 50]}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              />
            </Paper>
          ) : (
            <Typography>No time entries available</Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default HarvestTimeEntries;
