// src/components/AgedReceivables.tsx
import React, { useState, useCallback } from "react";
import DataTable from "./DataTable";
import api from "../utils/api";
import { Typography } from "@mui/material";

import { Column, Receivable } from "../types/general"; // Import Receivable interface

const AgedReceivables: React.FC = () => {
  const [receivables, setReceivables] = useState<Receivable[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchReceivables = useCallback(
    async (params: {
      search: string;
    }) => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get("/api/quickbooks/quickbooks/stored-aged-receivables", { params });
        setReceivables(response.data.records);
        setTotalCount(response.data.total || response.data.records.length);
      } catch (err) {
        console.error("Error fetching receivables:", err);
        setError("Failed to load receivables. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const columns: Column<Receivable>[] = [
    { id: "customerName", label: "Customer Name", sortable: true },
    { 
      id: "current", 
      label: "Current", 
      numeric: true, 
      sortable: true, 
      renderCell: (value: any, row: Receivable) => {
        return `${value}$`;  // Format as currency
      },
    },
    { 
      id: "oneToThirtyDays", 
      label: "1-30 Days", 
      numeric: true, 
      sortable: true, 
      renderCell: (value: any, row: Receivable) => {
        return `${value}$`;  // Format as currency
      },
    },
    { 
      id: "thirtyOneToSixtyDays", 
      label: "31-60 Days", 
      
      sortable: true, 
      renderCell: (value: any, row: Receivable) => {
        return `${value}$`;
      },
    },
    { 
      id: "sixtyOneToNinetyDays", 
      label: "61-90 Days", 
    
      sortable: true, 
      renderCell: (value: any, row: Receivable) => {
        return `${value}$`;
      },
    },
    { 
      id: "ninetyOneAndOver", 
      label: "91+ Days", 
      
      sortable: true, 
      renderCell: (value: any, row: Receivable) => {
        return `${value}$`; 
      },
    },
    { 
      id: "total", 
      label: "Total", 
      
      sortable: true, 
      renderCell: (value: any, row: Receivable) => {
        return `${value}$`;  // Format as currency
      },
    },
    { 
      id: "reportDate", 
      label: "Report Date", 
      sortable: true, 
      renderCell: (value: any, row: Receivable) => {
        return new Date(value).toLocaleDateString();  // Format the date
      },
    }
  ];
  
  return (
    <>
      {error && (
        <Typography variant="body1" color="error" mb={2}>
          {error}
        </Typography>
      )}
      <DataTable<Receivable>
        data={receivables}
        columns={columns}
        fetchData={fetchReceivables}
        loading={loading}
        totalCount={totalCount}
        pagination={true}
      />
    </>
  );
};

export default AgedReceivables;
