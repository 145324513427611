import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { ApiError } from '../../types/general';
import api from '../api';
import {
  CreateClientInput,
  CustomerType,
  GetCustomerDetailInput,
  GetCustomersInput,
  GetCustomersResponse,
  HarvestAndQuickBookIds,
  ICustomer,
  ICustomerDetails,
  IGetCustomersStats,
  SyncCustomersResponse,
  UpdateCustomerInput,
} from './dto';
import {
  getCustomerDetailQuery,
  getCustomersQuery,
  getCustomersCountQuery,
  getCustomerHarvestAndQBIdsQuery,
} from './queries';

export const useGetCustomers = (props: GetCustomersInput) =>
  useQuery<GetCustomersResponse, ApiError>({
    ...getCustomersQuery(props),
  });

  export const useGetInfiniteCustomers = (props: GetCustomersInput) => {
    const query = useInfiniteQuery<GetCustomersResponse, ApiError>({
      queryKey: ['customers', props],
      queryFn: async ({ pageParam }) =>
        (
          await api.get('/api/customer', {
            params: {
              ...props,
              pagination: { ...props.pagination, current: pageParam },
            },
          })
        ).data,
      getNextPageParam: ({ pagination: { current, pageSize, total } }) =>
        total - current * pageSize > 0 ? current + 1 : undefined,
      initialPageParam: 1,
    });
  
    return {
      ...query,
      data: query.data?.pages.flatMap((_data) => _data.data),
      totalCount: query.data?.pages[0]?.pagination?.total || 0,
    };
  };
  

  export const useGetCustomersCount = (
    groupId?: string,
    type?: keyof typeof CustomerType,
    parent?: keyof typeof CustomerType,
    additionalParams?: Record<string, any>, // Add this parameter
  ) =>
    useQuery<IGetCustomersStats, ApiError>({
      ...getCustomersCountQuery(groupId, type, parent, additionalParams),
    });

export const useGetCustomerDetail = (props: GetCustomerDetailInput) =>
  useQuery<ICustomerDetails, ApiError>({
    ...getCustomerDetailQuery(props),
  });

export const useSyncCustomers = () =>
  useMutation<SyncCustomersResponse, ApiError>({
    mutationKey: ['sync-customers'],
    mutationFn: async () => (await api.get('/api/customer/sync')).data,
  });

export const useUpdateCustomer = () =>
  useMutation<ICustomer, ApiError, UpdateCustomerInput>({
    mutationKey: ['update-customers'],
    mutationFn: async (props) =>
      (await api.put('/api/customer/update', props)).data,
  });

export const useCreateClient = () =>
  useMutation<ICustomer, ApiError, CreateClientInput>({
    mutationKey: ['create-client'],
    mutationFn: async (props) =>
      (await api.post('/api/customer/client', props)).data,
  });



export const useGetCustomerHarvestAndQBIds = (salesforceId: string) =>
  useQuery<HarvestAndQuickBookIds, ApiError>({
    ...getCustomerHarvestAndQBIdsQuery(salesforceId),
    enabled: !!salesforceId
  });
