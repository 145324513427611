// src/components/AgedPayableDetails.tsx
import React, { useState, useCallback } from "react";
import DataTable from "./DataTable";
import api from "../utils/api";
import { Typography } from "@mui/material";

import { Column, Payable } from "../types/general"; // Import Payable interface

const AgedPayableDetails: React.FC = () => {
  const [payableDetails, setPayableDetails] = useState<Payable[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPayableDetails = useCallback(
    async (params: {
      search: string;
    }) => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get("/api/quickbooks/quickbooks/stored-aged-payable-detail", { params });
        setPayableDetails(response.data.records);
        // Assuming no total count is needed as pagination is disabled
      } catch (err) {
        console.error("Error fetching payable details:", err);
        setError("Failed to load payable details. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  // Explicitly type columns as Column<Payable>[]
  const columns: Column<Payable>[] = [
    { id: "vendorName", label: "Vendor Name", sortable: true },
    { id: "txnType", label: "Transaction Type", sortable: true },
    { id: "docNum", label: "Document Number", sortable: true },
    { 
      id: "txnDate", 
      label: "Transaction Date", 
      sortable: true, 
      renderCell: (value: any, row: Payable) => {
        if (typeof value === "string" || value instanceof Date) {
          return new Date(value).toLocaleDateString();
        }
        return "N/A";
      },
    },
    { 
      id: "dueDate", 
      label: "Due Date", 
      sortable: true, 
      renderCell: (value: any, row: Payable) => {
        if (typeof value === "string" || value instanceof Date) {
          return new Date(value).toLocaleDateString();
        }
        return "N/A";
      },
    },
    { 
      id: "amount", 
      label: "Amount", 
      numeric: true, 
      sortable: true, 
      renderCell: (value: any, row: Payable) => {
          return `${value}$`;
      },
    },
    { 
      id: "openBalance", 
      label: "Open Balance", 
      numeric: true, 
      sortable: true, 
      renderCell: (value: any, row: Payable) => {
          return `${value}$`;
      }
    },
    { 
      id: "pastDue", 
      label: "Past Due (Days)", 
      numeric: true, 
      sortable: true, 
      renderCell: (value: any, row: Payable) => {
       
          return `${value} days`;
        
     
      },
    },
  ];

  return (
    <>
      {error && (
        <Typography variant="body1" color="error" mb={2}>
          {error}
        </Typography>
      )}
      <DataTable<Payable>
        data={payableDetails}
        columns={columns}
        fetchData={fetchPayableDetails}
        loading={loading}
        totalCount={payableDetails.length}
        pagination={false}
      />
    </>
  );
};

export default AgedPayableDetails;
