// src/components/DataTable.tsx
import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import {
  Box,
  CircularProgress,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Paper,
  Typography,
  InputAdornment,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Column } from "../types/general";


type Order = "asc" | "desc";

interface FetchParams {
  search: string;
}

interface DataTableProps<T> {
  columns: Column<T>[];
  fetchData: (params: FetchParams) => Promise<void>;
  loading: boolean;
  totalCount: number;
  data: T[];
  error?: string | null;
  pagination?: boolean;
}

function DataTable<T extends { id: string | number }>({
  columns,
  fetchData,
  loading,
  totalCount,
  data,
  error = null,
  pagination = true,
}: DataTableProps<T>): JSX.Element {
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof T>(columns[0].id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debouncedFetchData(event.target.value);
  };

  const debouncedFetchData = useCallback(
    debounce(
      (
        searchTerm: string,
      ) => {
        fetchData({
            search: searchTerm,
           
        });
      },
      300
    ),
    [fetchData]
  );

  useEffect(() => {
    fetchData({
      search,
    });
  }, [fetchData, search]);

  const handleRequestSort = (property: keyof T) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder: Order = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page with new rows per page
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          placeholder="Search..."
          value={search}
          onChange={handleSearchChange}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ mr: 2, flex: 1 }}
        />
      </Box>
      {error && (
        <Typography variant="body1" color="error" mb={2}>
          {error}
        </Typography>
      )}
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ width: "100%", overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={String(column.id)}
                    align={column.numeric ? "right" : "left"}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleRequestSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row) => (
                  <TableRow key={String(row.id)} hover>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={String(column.id)}
                          align={column.numeric ? "right" : "left"}
                        >
                          {column.renderCell
                            ? column.renderCell(value, row)
                            : (value as React.ReactNode)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No records found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {pagination && (
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          )}
        </Paper>
      )}
    </Box>
  );
}

export default DataTable;
