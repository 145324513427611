import React, { useState } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { lightTheme, darkTheme } from "./theme/customTheme";
import AppRoutes from "./routes/Routes";
import Providers from "./auth/Provider";
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
    <Providers>
      <CssBaseline />
      <AppRoutes />
    </Providers>
   </Router>
  );
}

export default App;
