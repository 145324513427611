// src/guards/Guard.tsx
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { useGetUser } from '../hooks/useUser';
import { getCookie } from '../utils/cookie';

interface GuardProps {
  children: ReactNode;
}

const Guard: React.FC<GuardProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthPath = location.pathname.includes('/dashboard');
  const unAuthPaths = ['/auth'];
  const isPublicPath = !isAuthPath && !unAuthPaths.includes(location.pathname);

  const { data: user, isLoading, error, refetch } = useGetUser();

  useEffect(() => {
    const retrieveToken = async () => {
      const retrievedToken = await getCookie('access_token') || localStorage.getItem('access_token');
      setToken(retrievedToken);
      setLoading(false);
    };

    retrieveToken();
  }, []);

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  useEffect(() => {
    if (loading) return;

    if (isPublicPath) return;

    if ((!token || (!isLoading && !user)) && isAuthPath) {
      navigate(`/auth?returnTo=${location.pathname}`, { replace: true });
    } else if (!isLoading && user && unAuthPaths.includes(location.pathname)) {
      navigate(`/dashboard`, { replace: true });
    }
  }, [loading, location.pathname, user, isLoading, isAuthPath, isPublicPath, navigate, token]);

  if (!isPublicPath && (loading || isLoading)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return <>{children}</>;
};

export default Guard;
