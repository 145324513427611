import { useMutation, UseMutationOptions, UseMutationResult, useQuery } from '@tanstack/react-query';
import { TrelloCard, TrelloFetchCardsInput, TrelloFetchCardsResponse } from '../types/trello';
import { ApiError } from '../types/general';
import api from '../utils/api';

export const useTrelloFetchCards = (textValue: string) =>
    useQuery<TrelloCard[], ApiError>({
      queryKey: ['trello-fetch-cards', textValue],
      queryFn: async () => {
        const response = await api.get('/api/trello/trello/cards', {
          params: { textValue },
        });
        return response.data;
      },
      enabled: Boolean(textValue),
    });

  export const useTrelloBoards = () =>
    useQuery({
      queryKey: ['trelloBoards'],
      queryFn: async () => {
        const response = await api.get('/api/trello/trello/boards');
        return response.data;
      },
    });
  
  export const useTrelloBoardLists = (boardId: string) =>
    useQuery({
      queryKey: ['trelloBoardLists', boardId],
      queryFn: async () => {
        const response = await api.get(`/api/trello/trello/boards/${boardId}/listsAndCards`);
        return response.data;
      },
      enabled: !!boardId,
    });

    export const useTrelloFetchBoardsAndLists = () =>
      useQuery({
        queryKey: ['trelloBoardsAndLists'],
        queryFn: async () => {
          // Fetch all boards
          const boardsResponse = await api.get('/api/trello/trello/boards');
          const boards = boardsResponse.data;
    
          // For each board, fetch its lists
          const boardsWithLists = await Promise.all(
            boards.map(async (board:any) => {
              const listsResponse = await api.get(
                `/api/trello/trello/boards/${board.id}/lists`
              );
              const lists = listsResponse.data;
              return { ...board, lists };
            })
          );
    
          return boardsWithLists;
        },
      });
    

      
      export interface CreateCardInput {
        listId: string;
        name: string;
        description?: string;
        additionalParams?: any;
        customFields?: { [key: string]: any };
      }
      type CreateCardMutationResult = UseMutationResult<any, ApiError, CreateCardInput, unknown>;

      export const useTrelloCreateCard = (
        options?: Omit<UseMutationOptions<any, ApiError, CreateCardInput, unknown>, 'mutationFn'>
      ): CreateCardMutationResult => {
        return useMutation<any, ApiError, CreateCardInput, unknown>({
          mutationFn: async (cardData: CreateCardInput) => {
            const response = await api.post('/api/trello/trello/cards', cardData);
            return response.data;
          },
          ...options,
        });
      };