import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../utils/api';
import { ApiError, AuthenticateServiceInput, GetAuthenticateServiceResponse, GetUserServicesResponse } from '../types/general';


export const getUserServicesQuery = () => ({
    queryKey: ['services'],
    queryFn: async () => (await api.get('/api/service')).data,
  });

  
export const useGetUserServices = () =>
  useQuery<GetUserServicesResponse, ApiError>({
    ...getUserServicesQuery(),
  });

export const useGetAuthenticatedServiceLink = () =>
  useMutation<
    GetAuthenticateServiceResponse,
    ApiError,
    AuthenticateServiceInput
  >({
    mutationKey: ['get-authenticated-service-link'],
    mutationFn: async (props) =>
      (await api.get('/api/service/authenticate', { params: props })).data,
  });
