// hooks/useUser.ts
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { IUser } from '../types/user';
import { useState } from 'react';

export const getUserQuery = () => ({
  queryKey: ['user'],
  queryFn: async () => {
    const response = await api.get('/api/user/me');
    return response.data;
  },
});

export const useGetUser = () => {
  const navigate = useNavigate();
  const [hasRedirected, setHasRedirected] = useState(false);

  return useQuery<IUser, Error>({
    ...getUserQuery(),
    retry: false,
    onError: (error:any) => {
        console.log("error here ",error)
    
    },
  } as UseQueryOptions<IUser, Error>);
};
