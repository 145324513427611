import { GridSortDirection } from "@mui/x-data-grid";
import { GetPaginatedInput, GetPaginatedResponse } from "../../types/general";

export interface TrustedContact {
  name?: string;
  phone?: string;
  emailAddress?: string;
  address?: Address;
}

export interface BankAccountInfo {
  bank_name?: string;
  routing?: string;
  account?: string;
  checking?: boolean;
  savings?: boolean;
}

export interface DriverLicense {
  number?: string;
  issue_date?: string;
  expiration_date?: string;
  state?: string;
  doc_id?: string;
}

export interface ICustomer {
  team_id: any;
  id: string;
  salesforce_contact_id: string;
  harvest_client_id: string;
  quick_book_id: string;
  first_name?: string;
  spouse_salesforce_id: string;
  full_name?: string;
  last_name?: string;
  email?: string;
  mobile_number?: string;
  phone?: string;
  birth_date?: string;
  age?: number;
  marital_status?: string;
  status?: string;
  attorney?: string;
  wm_service_level?: string;
  rating?: string;
  cfs_client_id?: string;
  new_cfs_client_id?:string;
  greeting?: string;
  gender?: string;
  household_id?: string;
  services_provided?: string[];
  home_phone?: string;
  work_phone?: string;
  primary_phone_type?: string;
  primary_phone?: string;
  secondary_email?: string;
  dependent_children?: ICustomer[];
  driver_license?: DriverLicense;
  trusted_contact?: TrustedContact;
  bank_account_info?: BankAccountInfo;
  description?: string;
  photo_url?: string;
  ssn?: string;
  city?: string;
  country?: string;
  postal_code?: string;
  createdAt: string;
  updatedAt: string;
  contact_primary: string | null;
  contact_spouse: string | null;
  mailing_address: Address;
  physical_address: Address;
  trello_account_id?: string | null;
  customer_type: string;
  Primary_Member_Employment?: string;
  Spouse_Employment?: string;
  website?: string; 
  fax_number?: string; 
  ein?: string; 
  ein_not_needed?: boolean;
  vendor?: string; // Vendor relationship
  investment_company?: string; // Investment Company relationship
  professional_contact?: string; // Professional Contact relationship
  annuity_company?: string; // Annuity Company relationship
  insurance_company?: string; // Insurance Company relationship
  insurance_broker?: string; // Insurance Broker relationship
  software_provider?: string; // Software Provider relationship
  newsletter_service?: string; // Newsletter Service relationship
  app_subscription?: string; // App Subscription relationship
  consultant?: string; // Consultant relationship
    // New business-specific fields
    dba?: string; // Doing Business As (DBA) name

    campaigns?: string; // Campaigns related to the business
    services_provided_1?: string; // First service provided
    services_provided_2?: string; // Second service provided
  
    // Business-related information
    business_start_date?: string; // Business Start Date
    s_corp_effective_date?: string; // S Corp Effective Date
    business_classification?: string; // Business Classification
    tr_due_date_month?: string; // Tax Return Due Date Month
    fiscal_year?: string; // Fiscal Year
    additional_state?: string; // Additional State for tax purposes
    tax_return?: string; // Tax Return
    accounting?: string; // Accounting information
    software?: string; // Software used for business operations
  
    // Legal entity type
    naics?: string; // NAICS number for the business
    industry?: string; // Industry of the business
    law_firm?: string; 
}

export interface ICustomerDetails {
  customer: ICustomer;
  spouseContact?: ICustomer;
  primaryContact?: ICustomer;
  familyContact?: ICustomer;
}

export type Address = {
  city: string | null;
  country: string | null;
  geocodeAccuracy: string | null;
  latitude: string | null;
  longitude: string | null;
  postalCode: string | null;
  state: string | null;
  street: string | null;
};

export type BasicContact = {
  FirstName: string | null;
  LastName: string | null;
  Phone: string | null;
  Name: string | null;
  Id: string;
};
export type Contacts = {
  Account_Spouse__r: BasicContact | null;
  Account_Primary__r: BasicContact | null;
};

export type ClientType = 'HARVEST';

export type SyncCustomersResponse = {
  success: boolean;
};

export type GetCustomersResponse = GetPaginatedResponse<ICustomer[]>;
export type GetNonHarvestCustomersCountResponse = GetPaginatedResponse<void>;

export type GetCustomersInput = GetPaginatedInput<{
  search?: string;
}> & {
  has_client?: boolean;
  group_id?: string | null;
  customer_type: keyof typeof CustomerType;
  sorting?: {
    sortField: string;
    sortDirection: GridSortDirection;
  };
  additionalFilters:any
};

export type GetCustomerDetailInput = {
  id: string;
};

export type CreateClientInput = {
  customer_id: string;
  type: ClientType;
};

export type UpdateCustomerInput = Partial<ICustomer>;

export type IGetCustomersStats = {
  totalcustomers: number;
  missingharvestid: number;
  missingquickbooksid: number;
  missingemail: number;
  missingmobilenumber: number;
  missingphone: number;
  missingssn: number;
  missingmailingaddress: number;
  missingphysicaladdress: number;
  totalactive: number;
};

export enum GroupId {
  Business = '012f2000000P02hAAC',
  BusinessClients = '012f20000001YNYAA2',
  EstateTrustAccounts = '0125x000000USKVAA4',
  Household = '012f2000000P02eAAC',
  Individual = '012f2000000P02iAAC',
  Institution = '012f2000000P02fAAC',
  PersonAccount = '012f2000000P02gAAC',
  PolicyHolder = '0125x000000E7enAAC',
  Master = '012000000000000AAA',
}
export enum CustomerType {
  Business = 'Business',
  BusinessClients = 'BusinessClients',
  EstateTrustAccounts = 'EstateTrustAccounts',
  Household = 'Household',
  Individual = 'Individual',
  Institution = 'Institution',
  PersonAccount = 'PersonAccount',
  PolicyHolder = 'PolicyHolder',
  Master = 'Master',
}

interface Project {
  projectId: string;
  name: string;
}

export interface HarvestAndQuickBookIds {
  qbId: string[] | null;
  harvestId: Project[] | null;
}
