// hooks/useInvoices.ts
import { useQuery } from '@tanstack/react-query';
import api from '../utils/api';
import { ApiError, InvoicesResponse } from '../types/general';

export const useGetInvoices = (email?: string) =>
  useQuery<InvoicesResponse[], ApiError>({
    queryKey: ['invoices', email],
    queryFn: async () => {
      if (!email) {
        return [];
      }
      return (
        await api.get('/api/quickbooks/quickbooks/invoices-by-email', {
          params: { email },
        })
      ).data;
    },
    enabled: !!email,
  });
