import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the access_token cookie exists
    const token = Cookies.get('access_token');

    if (token) {
      // Extract the returnTo state from the URL if available
      const params = new URLSearchParams(location.search);
      const state = JSON.parse(params.get('state') || '{}');
      const returnTo = state.returnTo || '/dashboard';

      // Navigate to the appropriate page
      navigate(returnTo, { replace: true });
    } else {
      // If no token is found, redirect to the auth page to login again
      navigate('/auth', { replace: true });
    }
  }, [navigate, location]);

  return <div>Authenticating...</div>;
};

export default AuthCallback;
