// src/hooks/useTeamsContext.ts
import { useState, useEffect } from 'react';
import { app, Context } from '@microsoft/teams-js';

interface TeamsContextState {
  inTeams: boolean;
  inTeamsTab: boolean;
}

export const useTeamsContext = () => {
  const [context, setContext] = useState<TeamsContextState | null>(null);

  useEffect(() => {
    const initializeTeams = async () => {
      try {
        await app.initialize();
        const appContext = await app.getContext();

        const inTeams = true;
        const inTeamsTab = appContext.channel !== undefined;

        setContext({
          inTeams,
          inTeamsTab
        });
      } catch (error) {
        console.error('Teams SDK initialization failed:', error);
        setContext({
          inTeams: false,
          inTeamsTab: false,
        });
      }
    };

    initializeTeams();
  }, []);

  return context;
};
