import React from "react";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { useNavigate, useLocation } from "react-router-dom";
import {
  SettingsRounded,
  InfoRounded,
  AccessTime,
  Book,
  Home,
  People,
} from "@mui/icons-material";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useGetUser } from "../../../hooks/useUser";


const reportTabs = [
  { key: "1", label: "Aged Receivables", link: "/office/quickbooks/aged-receivables" },
  { key: "2", label: "Aged Receivable Detail", link: "/office/quickbooks/aged-receivable-detail" },
  { key: "3", label: "Aged Payables", link: "/office/quickbooks/aged-payables" },
  { key: "4", label: "Aged Payable Detail", link: "/office/quickbooks/aged-payable-detail" },
];

export default function MenuContent() {

  const { data: user, isLoading } = useGetUser();
  const secondaryListItems = [
    ...(user?.is_admin
      ? [{ id: "settings", text: "Settings", icon: <SettingsRounded />, link: "/settings" }]
      : []),
    { id: "about", text: "Feedback", icon: <InfoRounded />, link: "https://forms.office.com/Pages/ResponsePage.aspx?id=m3yuP6jPZES-HkRhDQdjpdA1ozYBl_VBj0DS2H_QHmxUNFpZMTFaVzAyUEczWDJGVElHMlRJTE41Vi4u",external: true},
  ];
  
  const navigate = useNavigate();
  const location: any = useLocation();
  const [selectedItemId, setSelectedItemId] = React.useState<string | null>(null);

  const handleSelectedItemsChange = (
    event: React.SyntheticEvent,
    itemId: string | null
  ) => {
    if (itemId) {
      const selectedItem = findItemById(itemId, ITEMS);
      if (selectedItem?.link) {
        navigate(selectedItem.link);
      }
      setSelectedItemId(itemId); // Update the selected item state
    }
  };

  // Update selected item based on current location
  React.useEffect(() => {
    let matchedItem = findItemByLink(location, ITEMS);
    if (!matchedItem) {
      matchedItem = findItemByLink(location, secondaryListItems);
    }
    if (matchedItem) {
      setSelectedItemId(matchedItem.id);
    } else {
      setSelectedItemId(null); // Clear selection if no match
    }
  }, [location]);

  // Define menu items dynamically based on user role (admin or not)
  const ITEMS = [
    {
      id: "1",
      label: "Overview",
      icon: <Home />,
      link: "/",
    },
    {
      id: "2",
      label: "Clients",
      icon: <People />,
      children: [
        {
          id: "2.3",
          label: "Household",
          link: "/dashboard/customers?type=Household",
        },
        {
          id: "2.1",
          label: "Person Account",
          link: "/dashboard/customers?type=PersonAccount",
        },
        {
          id: "2.2",
          label: "Business Client",
          link: "/dashboard/customers?type=BusinessClients",
        },
      
        {
          id: "2.4",
          label: "Institution",
          link: "/dashboard/customers?type=Institution",
        },
      ],
    },
    {
      id: "3",
      label: "Office",
      icon: <AccessTime />,
      children: [
        {
          id: "3.1",
          label: "My Time Entries",
          link: "/office/my-time-entries",
        },
        // Conditionally render QuickBooks Reports and Harvest Project Management only for admins
        ...(user?.is_admin
          ? [
              {
                id: "3.2",
                label: "QuickBooks Reports",
                icon: <Book />,
                children: reportTabs.map((report) => ({
                  id: `3.2.${report.key}`,
                  label: report.label,
                  link: report.link,
                })),
              },
              {
                id: "3.3",
                label: "Harvest Project Management",
                link: "/office/harvest-project-management",
              },
            ]
          : []),
      ],
    },
  ];

  if (isLoading) return <div>Loading...</div>; // Loading state

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        <RichTreeView
          items={ITEMS}
          aria-label="menu"
          defaultExpandedItems={["2"]}
          selectedItems={selectedItemId}
          sx={{
            m: "0 -8px",
            pb: "8px",
            height: "fit-content",
            flexGrow: 1,
            overflowY: "auto",
            "& .MuiTreeItem-label": {
              color: "gray",
              fontSize: "0.875rem",
            },
            "& .MuiTreeItem-content.Mui-selected .MuiTreeItem-label": {
              color: "primary.main",
              fontSize: "0.875rem",
            },
          }}
          onSelectedItemsChange={handleSelectedItemsChange}
        />
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              selected={selectedItemId === item.id}
              onClick={() => {
                if (item.external) {
                  window.open(item.link, "_blank");
                } else {
                  navigate(item.link);
                }
                setSelectedItemId(item.id);
              }}
            >
              <ListItemIcon
                sx={{ color: selectedItemId === item.id ? "primary.main" : "gray" }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  color: selectedItemId === item.id ? "primary.main" : "gray",
                  fontSize: "0.875rem",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}

const findItemById = (id: string, items: any[]): any | null => {
  for (const item of items) {
    if (item.id === id) return item;
    if (item.children) {
      const found = findItemById(id, item.children);
      if (found) return found;
    }
  }
  return null;
};

const findItemByLink = (location: Location, items: any[]): any | null => {
  const locationUrl = new URL(location.pathname + location.search, window.location.origin);

  for (const item of items) {
    if (item.link) {
      const itemUrl = new URL(item.link, window.location.origin);
      if (itemUrl.href === locationUrl.href) {
        return item;
      }
    }
    if (item.children) {
      const found = findItemByLink(location, item.children);
      if (found) return found;
    }
  }
  return null;
};
