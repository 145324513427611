// src/components/AgedPayables.tsx
import React, { useState, useCallback } from "react";
import DataTable from "./DataTable";
import api from "../utils/api";
import { Typography } from "@mui/material";

import { AgedPayable, Column } from "../types/general"; // Import AgedPayable interface

const AgedPayables: React.FC = () => {
  const [payables, setPayables] = useState<AgedPayable[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchPayables = useCallback(async (params: {
    search: string;

  }) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/api/quickbooks/quickbooks/stored-aged-payables', { params });
      setPayables(response.data.records);
      setTotalCount(response.data.total || response.data.records.length);
    } catch (err) {
      console.error('Error fetching payables:', err);
      setError('Failed to load payables. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Explicitly type columns as Column<AgedPayable>[]
  const columns: Column<AgedPayable>[] = [
    { id: 'vendorName', label: 'Vendor Name', sortable: true },
    { 
      id: 'current', 
      label: 'Current', 
      numeric: true, 
      sortable: true, 
      renderCell: (value: any, row: AgedPayable) => `${value}$` 
    },
    { 
      id: 'oneToThirty', 
      label: '1 to 30 Days', 
      numeric: true, 
      sortable: true, 
      renderCell: (value: any, row: AgedPayable) => `${value}$` 
    },
    { 
      id: 'thirtyOneToSixty', 
      label: '31 to 60 Days', 
      numeric: true, 
      sortable: true, 
      renderCell: (value: any, row: AgedPayable) => `${value}$` 
    },
    { 
      id: 'sixtyOneToNinety', 
      label: '61 to 90 Days', 
      numeric: true, 
      sortable: true, 
      renderCell: (value: any, row: AgedPayable) => `${value}$` 
    },
    { 
      id: 'ninetyOneAndOver', 
      label: '91 and Over', 
      numeric: true, 
      sortable: true, 
      renderCell: (value: any, row: AgedPayable) => `${value}$` 
    },
    { 
      id: 'total', 
      label: 'Total', 
      numeric: true, 
      sortable: true, 
      renderCell: (value: any, row: AgedPayable) => `${value}$` 
    },
  ];

  return (
    <>
      {error && (
        <Typography variant="body1" color="error" mb={2}>
          {error}
        </Typography>
      )}
      <DataTable<AgedPayable>
        data={payables}
        columns={columns}
        fetchData={fetchPayables}
        loading={loading}
        totalCount={totalCount}
        pagination={false}
      />
    </>
  );
};

export default AgedPayables;
