import React from 'react';
import { Box, Button, Container, Paper, Typography, Divider, Avatar } from '@mui/material';
import { Microsoft } from '@mui/icons-material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Authentification = () => {
    const handleMicrosoftSignIn = () => {
        const returnTo = window.location.pathname !== '/auth' ? window.location.pathname : '/dashboard';
        const loginUrl = `${API_BASE_URL}/api/auth/staff/login?state=${JSON.stringify({ returnTo })}`;
        window.location.href = loginUrl;
      };
      

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: 3,
        }}
      >
        {/* Logo */}
        <Avatar
          alt="Company Logo"
          src="../assets/imgs/color.png"  
          sx={{ width: 200, height: 200 }}
        />
        <Divider sx={{ width: '100%', m: 3 }} />

        <Button
          fullWidth
          variant="contained"
          startIcon={<Microsoft />}
          onClick={handleMicrosoftSignIn}
          sx={{
            mt: 2,
            mb: 2,
            background: 'linear-gradient(45deg, #0078D4 30%, #00A4EF 90%)',
            color: 'white',
            padding: '10px 20px',
            fontSize: '16px',
            borderRadius: 2,
            '&:hover': {
              background: 'linear-gradient(45deg, #005A9E 30%, #0078D4 90%)',
            },
          }}
        >
          Connect with Microsoft
        </Button>
        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 3 }}>
          By signing in, you agree to our Terms of Service and Privacy Policy.
        </Typography>
      </Paper>
    </Container>
  );
};

export default Authentification;
