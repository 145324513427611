import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DescriptionIcon from "@mui/icons-material/Description";

import { ICustomer } from "../utils/customer";
import {
  useTrelloFetchCards,
  useTrelloCreateCard,
  useTrelloFetchBoardsAndLists,
  CreateCardInput,
} from "../hooks/useTrello";
import { TrelloCard } from "../types/trello";
import { useQuery, useMutation, useQueryClient, UseMutationResult } from "@tanstack/react-query";
import dayjs from "dayjs";
import { getProjectsByCodeQuery } from "../hooks/useHarvest";
import { ApiError } from "../types/general";

interface ProjectTaskManagementTabProps {
  customer: ICustomer;
}

function extractLabelUrlPairs(text: string): { label: string; url: string }[] {
  const regex = /(.*?):\s*(https?:\/\/[^\s]+)/g;
  const matches = [];
  let match;
  while ((match = regex.exec(text)) !== null) {
    matches.push({ label: match[1].trim(), url: match[2] });
  }
  return matches;
}

const ProjectTaskManagementTab: React.FC<ProjectTaskManagementTabProps> = ({
  customer,
}) => {
  const textValue = customer?.new_cfs_client_id || "";

  // Fetch Trello data
  const {
    data: trelloData,
    error: trelloError,
    isLoading: isTrelloLoading,
    refetch: refetchTrelloCards,
  } = useTrelloFetchCards(textValue);

  // Fetch Harvest projects
  const {
    data: projects,
    error: harvestError,
    isLoading: isHarvestLoading,
  } = useQuery({
    queryKey: ["projectsByCode", textValue],
    queryFn: () => getProjectsByCodeQuery({ code: textValue }).queryFn(),
    enabled: Boolean(textValue),
  });

  // State variables for modal and form
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBoardId, setSelectedBoardId] = useState("");
  const [selectedListId, setSelectedListId] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardDescription, setCardDescription] = useState("");

  // Fetch boards and lists
  const {
    data: boardsData,
    error: boardsError,
    isLoading: isBoardsLoading,
  } = useTrelloFetchBoardsAndLists();

  // Mutation for creating a card
  const queryClient = useQueryClient();
  const createCardMutation = useTrelloCreateCard({
    onSuccess: () => {
      // Close the modal and reset form fields
      setIsModalOpen(false);
      setSelectedBoardId("");
      setSelectedListId("");
      setCardName("");
      setCardDescription("");
      // Refresh the Trello cards
      refetchTrelloCards();
    },
    onError: (error: any) => {
      console.error("Error creating card:", error);
    },
  })as UseMutationResult<any, ApiError, CreateCardInput, unknown>;;

  // Handle modal open/close
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedBoardId("");
    setSelectedListId("");
    setCardName("");
    setCardDescription("");
  };

  // Handle form submission
  const handleCreateCard = () => {
    if (selectedListId && cardName) {
      createCardMutation.mutate({
        listId: selectedListId,
        name: cardName,
        description: cardDescription,
        customFields: {
          // Set your custom fields here if needed
          // For example, setting a custom field with the customer's ID
          // 'customFieldId': { text: customer.id },
        },
      });
    }
  };

  // Get lists for the selected board
  const selectedBoard = boardsData?.find(
    (board: any) => board.id === selectedBoardId
  );
  const lists = selectedBoard?.lists || [];

  return (
    <Box>
      {/* Add the "+" button */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">Trello</Typography>
        <IconButton color="primary" onClick={handleOpenModal}>
          <AddIcon />
        </IconButton>
      </Box>
      {isTrelloLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      ) : trelloError ? (
        <Typography color="error">Error loading Trello data.</Typography>
      ) : trelloData && trelloData.length > 0 ? (
        trelloData.map((card: TrelloCard) => {
          const labelUrlPairs = card.desc
            ? extractLabelUrlPairs(card.desc)
            : [];
          const descriptionWithoutUrls = card.desc
            ? card.desc.replace(/(.*?):\s*(https?:\/\/[^\s]+)/g, "").trim()
            : "";

          return (
            <Card key={card.id} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {card.name}
                </Typography>
                {card.boardName && (
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    gutterBottom
                  >
                    <strong>Board:</strong> {card.boardName}
                  </Typography>
                )}
                {descriptionWithoutUrls && (
                  <Box display="flex" alignItems="center" mb={1}>
                    <DescriptionIcon sx={{ mr: 1 }} />
                    <Typography>{descriptionWithoutUrls}</Typography>
                  </Box>
                )}
                {labelUrlPairs.length > 0 && (
                  <Box mb={1}>
                    <Typography variant="subtitle1">Links:</Typography>
                    {labelUrlPairs.map(({ label, url }, index) => (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        mt={1}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {label}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                )}
                <Divider sx={{ my: 2 }} />
                <Box display="flex" alignItems="center" mb={1}>
                  <EventIcon sx={{ mr: 1 }} />
                  <Typography>
                    <strong>Last Activity Date:</strong>&nbsp;
                    {new Date(card.dateLastActivity).toLocaleString()}
                  </Typography>
                </Box>
                {card.due && (
                  <Box display="flex" alignItems="center" mb={1}>
                    <AccessTimeIcon sx={{ mr: 1 }} />
                    <Typography>
                      <strong>Due Date:</strong>&nbsp;
                      {new Date(card.due).toLocaleString()}
                    </Typography>
                  </Box>
                )}
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  href={card.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Trello
                </Button>
              </CardActions>
            </Card>
          );
        })
      ) : (
        <Typography>No Trello data available.</Typography>
      )}
      {/* Harvest Section */}
      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        Harvest Projects
      </Typography>
      {isHarvestLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      ) : harvestError ? (
        <Typography color="error">Error loading Harvest data.</Typography>
      ) : projects && projects.length > 0 ? (
        projects.map((project: any) => (
          <Card key={project.id} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {project.name}
              </Typography>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography>
                  <strong>Active:</strong> {project.is_active ? "Yes" : "No"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography>
                  <strong>Billable:</strong>{" "}
                  {project.is_billable ? "Yes" : "No"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography>
                  <strong>Budget:</strong> {project.budget || "N/A"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography>
                  <strong>Last Updated Date:</strong>{" "}
                  {project.mostRecentEntry?.spent_date
                    ? dayjs(project.mostRecentEntry.spent_date).format(
                        "MM/DD/YYYY"
                      )
                    : "N/A"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography>
                  <strong>Total Hours:</strong> {project.totalHours || "0"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography>
                  <strong>Total Billable Hours:</strong>{" "}
                  {project.totalBillableHours || "0"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography>
                  <strong>Total Non-Billable Hours:</strong>{" "}
                  {project.totalNonBillableHours || "0"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography>
                  <strong>Average Time Per Entry:</strong>{" "}
                  {project.averageTimePerEntry
                    ? `${project.averageTimePerEntry.toFixed(2)} hours`
                    : "N/A"}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography>No Harvest projects found for this customer.</Typography>
      )}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Create a New Trello Card</DialogTitle>
        <DialogContent>
          {isBoardsLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress />
            </Box>
          ) : boardsError ? (
            <Typography color="error">Error loading Trello boards.</Typography>
          ) : (
            <>
              <TextField
                select
                label="Board"
                value={selectedBoardId}
                onChange={(e) => {
                  setSelectedBoardId(e.target.value);
                  setSelectedListId(""); // Reset the list selection
                }}
                fullWidth
                margin="normal"
              >
                {boardsData?.map((board: any) => (
                  <MenuItem key={board.id} value={board.id}>
                    {board.name}
                  </MenuItem>
                ))}
              </TextField>
              {selectedBoardId && (
                <TextField
                  select
                  label="List"
                  value={selectedListId}
                  onChange={(e) => setSelectedListId(e.target.value)}
                  fullWidth
                  margin="normal"
                >
                  {lists.map((list: any) => (
                    <MenuItem key={list.id} value={list.id}>
                      {list.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {selectedListId && (
                <>
                  <TextField
                    label="Card Name"
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Description"
                    value={cardDescription}
                    onChange={(e) => setCardDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                  />
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            onClick={handleCreateCard}
            variant="contained"
            color="primary"
            disabled={
              !selectedListId ||
              !cardName ||
              createCardMutation.status === "pending"
            }
          >
            {createCardMutation.status === "pending" ? (
              <CircularProgress size={24} />
            ) : (
              "Create Card"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProjectTaskManagementTab;
