import { useQuery } from '@tanstack/react-query';
import api from '../utils/api';

interface GetProjectsByCodeInput {
  code: string;
}

export const getProjectsByCodeQuery = (props: GetProjectsByCodeInput) => ({
  queryKey: ['projectsByCode', props.code],
  queryFn: async () => (await api.get(`/api/service/harvest/code/${props.code}`)).data,
});
