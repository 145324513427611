import React, { useState } from "react";
import { Tabs, Tab, Box, Typography, Divider } from "@mui/material";
import TaskIcon from "@mui/icons-material/Task";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentIcon from "@mui/icons-material/Payment";
import WorkflowIcon from "@mui/icons-material/WorkOutline";
import ProjectTaskManagementTab from "../ProjectTaskManagementTab";
import { ICustomer } from "../../utils/customer";
import InvoicesTab from "../InvoicesTab";
import ProcessWorkflowsTab from "../ProcessWorkTab";

interface TabsComponentProps {
  customer: ICustomer;
  spouseContact?: ICustomer;
  familyContact?: ICustomer;
  primaryContact?: ICustomer;
}

const TabsComponent: React.FC<TabsComponentProps> = ({ customer,spouseContact,familyContact,primaryContact }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Tabs Header */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab icon={<TaskIcon />} label="Project & Task Mgmt" />
        <Tab icon={<ReceiptIcon />} label="Invoices" />
        {/* <Tab icon={<PaymentIcon />} label="Payments" /> */}
        <Tab icon={<WorkflowIcon />} label="Process Workflows" />
      </Tabs>

      {/* Tab Panels */}
      <Box sx={{ p: 3 }}>
        {selectedTab === 0 && <ProjectTaskManagementTab customer={customer} />}
        {selectedTab === 1 && <InvoicesTab customer={customer} />}
        {/* {selectedTab === 2 && <PaymentsTab customer={customer} />} */}
        {selectedTab === 2 && (
          <ProcessWorkflowsTab
            customer={customer}
            spouseContact={spouseContact}
            familyContact={familyContact}
            primaryContact={primaryContact}
          />
        )}
      </Box>
    </Box>
  );
};

export default TabsComponent;
