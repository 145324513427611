import React from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ICustomer } from '../utils/customer';
import { ApiError } from '../types/general';
import { useGetInvoices } from '../hooks/useInvoices';

interface InvoicesTabProps {
  customer: ICustomer;
}

const InvoicesTab: React.FC<InvoicesTabProps> = ({ customer }) => {
  const email = customer?.email || '';

  const {
    data: invoiceData,
    error: invoiceError,
    isLoading: isInvoiceLoading,
  } = useGetInvoices(email);

  if (isInvoiceLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (invoiceError) {
    return <Typography color="error">Error loading invoices.</Typography>;
  }

  if (!invoiceData || invoiceData.length === 0) {
    return <Typography>No invoices found for this customer.</Typography>;
  }

  return (

    <Box sx={{maxWidth:"100%" , margin: '0 auto' }}>
      {invoiceData.map((invoice: any) => (
        <Card key={invoice.id} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Invoice #{invoice.docNumber}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box display="flex" flexDirection="column">
              <Typography>
                <strong>Date:</strong> {invoice.txnDate}
              </Typography>
              <Typography>
                <strong>Customer:</strong> {invoice.customerName}
              </Typography>
              <Typography>
                <strong>Total Amount:</strong> ${invoice.totalAmt}
              </Typography>
              <Typography>
                <strong>Balance:</strong> ${invoice.balance}
              </Typography>
              <Typography>
                <strong>Due Date:</strong> {invoice.dueDate}
              </Typography>
              <Typography>
                <strong>Sales Term:</strong> {invoice.salesTerm}
              </Typography>
              <Typography>
                <strong>Billing Email:</strong> {invoice.billEmail}
              </Typography>
              <Typography>
                <strong>Print Status:</strong> {invoice.printStatus}
              </Typography>
              <Typography>
                <strong>Email Status:</strong> {invoice.emailStatus}
              </Typography>
            </Box>

            {/* Line Items */}
            {invoice.lines && invoice.lines.length > 0 && (
              <Accordion sx={{ mt: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Line Items</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Item</strong></TableCell>
                        <TableCell><strong>Description</strong></TableCell>
                        <TableCell><strong>Amount</strong></TableCell>
                        <TableCell><strong>Quantity</strong></TableCell>
                        <TableCell><strong>Unit Price</strong></TableCell>
                        <TableCell><strong>Tax Code</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoice.lines.map((line: any, index: number) => (
                        <TableRow key={`${invoice.id}-${index}`}>
                          <TableCell>{line.itemRef}</TableCell>
                          <TableCell>{line.description || '(none provided)'}</TableCell>
                          <TableCell>${line.amount}</TableCell>
                          <TableCell>{line.qty}</TableCell>
                          <TableCell>${line.unitPrice}</TableCell>
                          <TableCell>{line.taxCodeRef}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            )}

            {/* Payments */}
            {invoice.paymentLines && invoice.paymentLines.length > 0 && (
              <Accordion sx={{ mt: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Payments</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Payment ID</strong></TableCell>
                        <TableCell><strong>Customer Name</strong></TableCell>
                        <TableCell><strong>Customer Ref ID</strong></TableCell>
                        <TableCell><strong>Currency</strong></TableCell>
                        <TableCell><strong>Total Amount</strong></TableCell>
                        <TableCell><strong>Payment Date</strong></TableCell>
                        <TableCell><strong>Private Note</strong></TableCell>
                        <TableCell><strong>Process Payment</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoice.paymentLines.map((paymentLine: any) => (
                        <TableRow key={paymentLine.id}>
                          <TableCell>{paymentLine.payment?.id}</TableCell>
                          <TableCell>{paymentLine.payment?.customerName}</TableCell>
                          <TableCell>{paymentLine.payment?.customerRefId}</TableCell>
                          <TableCell>{paymentLine.payment?.currency}</TableCell>
                          <TableCell>${paymentLine.payment?.totalAmt}</TableCell>
                          <TableCell>
                            {new Date(paymentLine.payment?.txnDate).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{paymentLine.payment?.privateNote}</TableCell>
                          <TableCell>
                            {paymentLine.payment?.processPayment ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default InvoicesTab;
