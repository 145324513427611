import { useMutation } from "@tanstack/react-query";
import api from "../utils/api";

interface SyncCustomersResponse {
  success: boolean;
  message: string;
}

interface ApiError {
  message: string;
}

// Custom hook for syncing customers
export const useSyncCustomers = () =>
    useMutation<SyncCustomersResponse, ApiError>({
      mutationKey: ["sync-customers"],
      mutationFn: async () => {
        const response = await api.get("/api/customer/sync");
        return response.data;
      },
    });
