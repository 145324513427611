import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useGetCustomersCount } from '../../../utils/customer';



interface StyledTextProps {
  variant: 'primary' | 'secondary';
}

const StyledText = styled('text', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<StyledTextProps>(({ theme }) => ({
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fill: (theme || theme).palette.text.secondary,
  variants: [
    {
      props: {
        variant: 'primary',
      },
      style: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
    {
      props: ({ variant }) => variant !== 'primary',
      style: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
    {
      props: {
        variant: 'primary',
      },
      style: {
        fontWeight: theme.typography.h5.fontWeight,
      },
    },
    {
      props: ({ variant }) => variant !== 'primary',
      style: {
        fontWeight: theme.typography.body2.fontWeight,
      },
    },
  ],
}));

interface PieCenterLabelProps {
  primaryText: string;
  secondaryText: string;
}

function PieCenterLabel({ primaryText, secondaryText }: PieCenterLabelProps) {
  const { width, height, left, top } = useDrawingArea();
  const primaryY = top + height / 2 - 10;
  const secondaryY = primaryY + 24;

  return (
    <React.Fragment>
      <StyledText variant="primary" x={left + width / 2} y={primaryY}>
        {primaryText}
      </StyledText>
      <StyledText variant="secondary" x={left + width / 2} y={secondaryY}>
        {secondaryText}
      </StyledText>
    </React.Fragment>
  );
}

const colors = [
  'hsl(220, 20%, 65%)',
  'hsl(220, 20%, 42%)',
  'hsl(220, 20%, 35%)',
  'hsl(220, 20%, 25%)',
];

export default function ChartUserByCountry() {
  const { data: PersonAccountCount } = useGetCustomersCount(
    undefined, // group_id
    "PersonAccount", // type
    undefined, // parent
    undefined// additionalParams
  );
  const { data: HouseholdCount } = useGetCustomersCount(
    undefined, // group_id
    "Household", // type
    undefined, // parent
    undefined// additionalParams
  );
  const { data: businessCount } = useGetCustomersCount(
    undefined, // group_id
    "BusinessClients", // type
    undefined, // parent
    undefined// additionalParams
  );
  const { data: InstitutionCount } = useGetCustomersCount(
    undefined, // group_id
    'Institution', // type
    undefined, // parent
    undefined// additionalParams
  );
  const { data: customerCounts, isLoading, error } = useGetCustomersCount(
    undefined, // group_id
    undefined, // type
    undefined, // parent
    undefined// additionalParams
  );

  const data = [
    { label: 'Person Account', value: PersonAccountCount?.totalcustomers as number },
    { label: 'Household', value: HouseholdCount?.totalcustomers  as number},
    { label: 'Institution', value: InstitutionCount?.totalcustomers as number},
    { label: 'business', value: businessCount?.totalcustomers as number},
  ];


  const personAccountPercentage=customerCounts?.totalcustomers && PersonAccountCount?.totalcustomers && PersonAccountCount?.totalcustomers*100/customerCounts?.totalcustomers
  const HouseHoldPercentage=customerCounts?.totalcustomers && HouseholdCount?.totalcustomers && HouseholdCount?.totalcustomers*100/customerCounts?.totalcustomers
  const InstitutionPercentage=customerCounts?.totalcustomers && InstitutionCount?.totalcustomers && InstitutionCount?.totalcustomers*100/customerCounts?.totalcustomers
  const businessPercentage=customerCounts?.totalcustomers && businessCount?.totalcustomers && businessCount?.totalcustomers*100/customerCounts?.totalcustomers
  const countries = [
    {
      name: 'Person account',
      value: Math.floor(personAccountPercentage as number ) ,
      color: 'hsl(220, 25%, 65%)',
    },
    {
      name: 'Household',
      value: Math.floor(HouseHoldPercentage as number ),
      color: 'hsl(220, 25%, 45%)',
    },
    {
      name: 'Institution',
      value: Math.floor(InstitutionPercentage as number ),
      color: 'hsl(220, 25%, 30%)',
    },
    {
      name: 'business',
      value: Math.floor(businessPercentage as number ),
      color: 'hsl(220, 25%, 20%)',
    },
  ];

  return (
    <Card
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}
    >
      <CardContent>
        <Typography component="h2" variant="subtitle2">
          Users by Type
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PieChart
            colors={colors}
            margin={{
              left: 80,
              right: 80,
              top: 80,
              bottom: 80,
            }}
            series={[
              {
                data,
                innerRadius: 75,
                outerRadius: 100,
                paddingAngle: 0,
                highlightScope: { faded: 'global', highlighted: 'item' },
              },
            ]}
            height={260}
            width={260}
            slotProps={{
              legend: { hidden: true },
            }}
          >
            <PieCenterLabel primaryText={""+customerCounts?.totalcustomers} secondaryText="Total" />
          </PieChart>
        </Box>
        {countries.map((country, index) => (
          <Stack
            key={index}
            direction="row"
            sx={{ alignItems: 'center', gap: 2, pb: 2 }}
          >
            {/* {country.flag} */}
            <Stack sx={{ gap: 1, flexGrow: 1 }}>
              <Stack
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: '500' }}>
                  {country.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {country.value}%
                </Typography>
              </Stack>
              <LinearProgress
                variant="determinate"
                aria-label="Number of users by country"
                value={country.value}
                sx={{
                  [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: country.color,
                  },
                }}
              />
            </Stack>
          </Stack>
        ))}
      </CardContent>
    </Card>
  );
}
