import { useMutation } from '@tanstack/react-query';
import { ApiError, SocialLoginInput, SocialLoginResponse } from '../types/general';
import api from './api';

export const useSocialLogin = () =>
  useMutation<SocialLoginResponse, ApiError, SocialLoginInput>({
    mutationKey: ['social-login'],
    mutationFn: async (props) =>
      (await api.post('/api/auth/social-login', props)).data,
  });

export const useExchangeTeamsToken = () =>
  useMutation<
    { token: string; customer_id: string },
    ApiError,
    { token: string; state: string }
  >({
    mutationKey: ['exchange-token'],
    mutationFn: async (props) =>
      (await api.get('/api/auth/team-login', { params: props })).data,
  });


  export const inTeams=()=> {
    return (
      typeof window !== 'undefined' &&
      (window.name === 'embedded-page-container' ||
        window.name === 'extension-tab-frame' ||
        window.name === 'platform-website-tab')
    );
  }