// src/components/CustomerDetails.tsx
import React from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
import SalesforceDetails from '../pages/SalesforceDetails';
import TabsComponent from '../components/TabsComponent';
import { useParams } from 'react-router-dom';
import { useGetCustomerDetail } from '../utils/customer';
import { useTeamsContext } from '../hooks/useTeamsContext';
import { useTheme } from '@mui/material/styles';
// Import SVG icons
import SalesforceLogo from '../assets/salesforce.svg';
import TeamsLogo from '../assets/teams.svg';

const CustomerDetails = () => {
  const { customerId } = useParams<{ customerId: any }>();
  const { data: customerDetailResponse, isLoading, error } = useGetCustomerDetail({ id: customerId });
  const context = useTeamsContext();
  const theme = useTheme();

  // Loading State
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  // Error State
  if (error || !customerDetailResponse) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h6" color="error">
          Error loading customer details.
        </Typography>
      </Box>
    );
  }

  const { customer, spouseContact, familyContact, primaryContact } = customerDetailResponse;

  // Conditionally apply padding
  const outerPadding = context?.inTeamsTab ? theme.spacing(2) : theme.spacing(0);

  // Salesforce and Teams URLs
  const salesforceUrl = `https://your-salesforce-instance.com/${customer.id}`; // Replace with actual URL
  const teamsUrl = customer.team_id ? `https://teams.microsoft.com/l/team/${customer.team_id}` : null;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'row',
        p: outerPadding,
      }}
    >

      <Box
        sx={{
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
          minWidth: '30%',
          overflow: 'hidden',
        }}
      >

        {/* Content with Independent Scrolling */}
        <Box
          sx={{
            overflowY: 'auto',
            flex: 1,
          }}
        >
          <SalesforceDetails
            customer={customer}
            spouseContact={spouseContact}
            familyContact={familyContact}
            primaryContact={primaryContact}
          />
        </Box>
      </Box>

      <Divider orientation="vertical" flexItem />

      {/* Right Pane */}
      <Box
        component="main"
        sx={{
          flex: 3,
          p: theme.spacing(3),
          overflowY: 'auto',
        }}
      >
        <TabsComponent
          customer={customer}
          spouseContact={spouseContact}
          familyContact={familyContact}
          primaryContact={primaryContact}
        />
      </Box>
    </Box>
  );
};

export default CustomerDetails;
