import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import MainGrid from '../components/dashboard/components/MainGrid';

export default function Overview() {
  return (
    <Box
      sx={{
        width:"100%",
        display: 'flex',
        flexGrow: 1,
        overflow: 'auto',
      }}
    >
        <MainGrid />
    </Box>
  );
}
