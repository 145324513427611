import * as React from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChartUserByCountry from "./ChartUserByCountry";
import StatCard, { StatCardProps } from "./StatCard";
import { useGetCustomersCount } from "../../../utils/customer";

export default function MainGrid() {
  const {
    data: customerCounts,
    isLoading,
    error,
  } = useGetCustomersCount(
    undefined, // group_id
    undefined, // type
    undefined, // parent
    undefined // additionalParams
  );

  const data: StatCardProps[] = [
    {
      title: "Clients",
      value: "" + customerCounts?.totalcustomers,
      interval: "Last 30 days",
      trend: "neutral",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      title: "Active Clients",
      value: "" + customerCounts?.totalactive,
      interval: "Last 30 days",
      trend: "neutral",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Overview
      </Typography>

      <Grid
        container
        spacing={2}
        columns={12}
        alignItems="flex-start"
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        <Grid size={{ xs: 12, lg: 3 }}>
          <Stack gap={2} direction={{ xs: "column", sm: "row", lg: "column" }}>
            <ChartUserByCountry />
          </Stack>
        </Grid>
        {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
