
import axios from 'axios';
import { API_BASE_URL } from '../Config/config';
import { getCookie } from './cookie';
import Cookies from 'js-cookie';

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const getAuthToken = (): string | null => {
  return Cookies.get('access_token') || localStorage.getItem('access_token');
};

api.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle unauthorized responses
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Optionally, remove invalid token
      localStorage.removeItem('access_token');
      Cookies.remove('access_token');
      // Optionally, redirect to /auth or trigger a global logout
      // window.location.href = `/auth?returnTo=${encodeURIComponent(window.location.pathname)}`;
    }
    return Promise.reject(error);
  }
);

export default api;
